export default {
  lectures: 'Lectures Streaming',
  lectures_archive: 'Lecture Archive',
  program: 'Program',
  chats: 'Chats',
  showcase: 'Showcase',
  members: 'Attendees',
  companies: 'Companies',
  consultants: 'Consultants',
  vacancies: 'Jobs',
  meetings: 'Meetings',
  speakers: 'Speakers',
  group_call: 'Group call',
  agenda: 'Agenda',
  contacts: 'Contacts',
  events: 'Events',
  publishers: 'Publisher Pavilion',
  host: 'Host your event',
  expo: 'Expo Zone',
  media: 'Media Partners',
  our_media: 'Our Media',
  partyspace: 'Party Space',
  news: 'News'
}
