import config from './config'

declare let WebFont: {
  load: (data: Record<string, any>) => void
}

async function loadScript(src: string) {
  return new Promise((resolve, reject) => {
    const head = document.head || document.getElementsByTagName('head')[0]
    const script = document.createElement('script')

    script.async = true
    script.charset = 'utf-8'
    script.src = src

    head.appendChild(script)

    script.onload = () => resolve({ success: true })
    script.onerror = error => reject(error)
  })
}

function loadFont(family: string) {
  WebFont.load({
    google: {
      families: [`${family}:300,400,500,600,700,800,900`]
    }
  })
}

function setFont(font: string) {
  document.body.style.fontFamily = `'${font}', serif`
}

async function createWebFont() {
  return await loadScript(config.scriptSrc)
}

export function useWebFont() {
  return {
    createWebFont,
    loadFont,
    setFont
  }
}
