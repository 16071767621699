export default {
  create: 'Создание встречи',
  create_online: 'Создание онлайн встречи',
  is_online: 'Онлайн встреча',
  agenda: 'Тема встречи',
  date: 'Дата',
  time_slot: 'Время',
  time: 'Время',
  request: 'Запросить',
  participants: 'Участники',
  cancel: 'Отмена',
  meetings: 'Встречи',
  meeting: 'Встреча',
  join_meeting: 'Видеозвонок',
  calendar: 'Календарь',
  select_timeslot_prompt: 'Выберите время',
  today: 'Сегодня',
  invites: 'Приглашения',
  upcoming: 'Предстоящие',
  location: 'Локация',
  online: 'Онлайн',
  onsite: 'On-site',
  invited: 'Приглашен',
  accepted: 'Принято',
  rejected: 'Отклонено',
  cancel_verb: 'Отменить',
  reschedule: 'Перенести',
  send_message: 'Отправить сообщение',
  reject: 'Отклонить',
  accept: 'Принять',
  reason: 'Причина',
  cancel_meeting: 'Отменить встречу',
  save: 'Сохранить',
  day: 'День',
  days: 'Дней',
  week: 'Неделя',
  time_slot_locked_message: `Слот заблокирован.
  Разблокируйте его в календаре или перенесите встречу на другой слот.`,
  time_slot_busy_message: 'Слот занят.\n Перенесите встречу на другой слот.',
  no_meetings: 'Нет предстоящих встреч',
  status_waiting: 'исходящее приглашение',
  status_declined: 'отменено',
  status_accepted: 'принято',
  status_incoming: 'входящее приглашение',
  unlock: 'разблокировать',
  lock: 'заблокировать',
  location_hint_text: 'Чтобы сориентироваться в экспозоне, ознакомьтесь с',
  location_hint_link: 'планом этажа'

}
