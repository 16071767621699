import { ref } from 'vue'

const NOTIFICATION_VISIBILITY_DURATION = 8000
const TITLE_CHANGE_DURATION = 1500
const DEFAULT_DOCUMENT_TITLE = 'WN Hub'
const SOUND_PATH = '/sound/notification.mp3'

export interface NotificationInterface {
  title: string
  description?: string
  type?: string
  icon?: string
  theme?: string
  playSound?: boolean
  changeTitle?: boolean
  clickHandler?: () => void
}

export class Notification implements NotificationInterface {
  title!: string
  description!: string
  type!: string
  icon = 'outline_check_circle'
  theme = 'success'
  playSound!: boolean
  changeTitle!: boolean
  clickHandler?: () => void

  constructor(params: NotificationInterface) {
    params.title && (this.title = params.title)
    params.description && (this.description = params.description)
    params.icon && (this.icon = params.icon)
    params.theme && (this.theme = params.theme)
    params.type && (this.type = params.type)
    params.playSound && (this.playSound = params.playSound)
    params.changeTitle && (this.changeTitle = params.changeTitle)
    params.clickHandler && (this.clickHandler = params.clickHandler)
  }
}

const state = ref([] as Notification[])

let intervalId: number

function pushNotification(params: NotificationInterface) {
  const notification = new Notification(params)

  state.value.push(notification)

  if (notification.playSound) {
    const sound = new Audio(SOUND_PATH)

    sound.autoplay = true
    sound.muted = false

    sound.oncanplaythrough = () => {
      sound.play()
    }
  }

  if (notification.changeTitle) {
    if (intervalId) {
      clearInterval(intervalId)
    }

    intervalId = setTitleChangeInterval(notification.title)
  }

  setTimeout(() => {
    if (state.value.length > 0) {
      state.value.splice(0, 1)
    }
    if (!state.value.length && intervalId) {
      clearInterval(intervalId)
      document.title = DEFAULT_DOCUMENT_TITLE
    }
  }, NOTIFICATION_VISIBILITY_DURATION)
}

function removeNotification(notification: Notification) {
  const i = state.value.indexOf(notification)

  if (i === state.value.length - 1) {
    if (intervalId) {
      clearInterval(intervalId)
      document.title = DEFAULT_DOCUMENT_TITLE
    }
    const newLast = state.value[i - 1]

    if (newLast?.changeTitle) {
      intervalId = setTitleChangeInterval(newLast.title)
    }
  }
  state.value.splice(i, 1)
}

function setTitleChangeInterval(title: string) {
  let showTitle = true

  return setInterval((function func() {
    document.title = showTitle ? title : DEFAULT_DOCUMENT_TITLE
    showTitle = !showTitle
    return func
  }()), TITLE_CHANGE_DURATION)
}

export function useNotifications() {
  return { list: state, pushNotification, removeNotification }
}
