export default {
  source: 'Source',
  vote_creativity: '创意',
  vote_relevance: '关联',
  vote_innovation: '创新',
  vote_winner: '赢家',
  vote_note: '每个分类你只能投一次',
  vote: '投票',
  show_previous: '过往活动参会者',
  local_time: '当地时间',
  help_text: '假如您有任何疑问，请发给我们发邮件，我们会尽快联系您。',
  contacts_phone: '电话',
  contacts_site: '网站',
  contacts_email: '邮箱',
  contacts: '联系人',
  get_a_ticket: '买票',
  submit: '提交',
  menu: '菜单',
  required: '必填项',
  settings: '设置',
  about: '简介',
  logout: '退出',
  email: '电子邮箱地址',
  name: '姓名',
  password: '密码',
  repeat_password: '重复输入密码',
  new_password: '新密码',
  change_password: '更改密码',
  change_password_description: '输入当前密码，并创建一个新密码',
  save: '保存',
  help: '帮助',
  yes: '是',
  no: '否',
  help_text_1: '如果您有任何疑问，请向我们发送电子邮件：',
  help_text_2: '我们会尽快回复您!',
  search: '搜索',
  search_placeholder: '搜索……',
  tags: '标签',
  company: '公司',
  offer: '招聘职位',
  look_for: '寻找',
  all_variants: '所有不同选择',
  regions: '地区',
  all_regions: '所有地区',
  country: '国家和地区',
  all_countries: '所有国家',
  city: '城市',
  all_cities: '所有城市',
  platform: '平台',
  stage: '阶段',
  monetization: '变现',
  description: '描述',
  icon: '图标',
  cover: '封面',
  photo: '照片',
  photos: '照片',
  genre: '类型',
  engine: '引擎',
  organization: '组织',
  call: '致电',
  not_select: '没有选择',
  select_prompt: '点击选择',
  or: '或',
  got_it: '知道了',
  delete: '删除',
  cancel: '取消',
  filters: '筛选',
  show: '显示',
  error: '错误',
  file_is_large: '上传的文件过大',
  file_is_not_supported: '不支持上传的文件格式',
  file_upload_error: '上传失败',
  previous: '上一步',
  next: '下一步',
  link: '链接',
  links: '链接',
  add_link: '添加链接',
  copy_link: '复制链接',
  sort_by: '排序依据',
  no_sort: '无排序',
  registration_date: '注册日期',
  last_seen: '上次看到',
  over: '超过',
  sure_to_leave: '您是否确定要离开此页面？',
  table_empty: '空表',
  table_empty_description: '没有可显示的数据。',
  timezone: '时区',
  back: '返回',
  business_member: '企业会员',
  no_matches_message: '没有可显示的数据。',
  clear_filters: '取消筛选',
  features: '参会者类型',
  features_offline: '只参加线下',
  day: { other: '天' },
  hour: { other: '小时' },
  minute: { other: '分钟' },
  second: { other: '秒' },
}
