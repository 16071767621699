export default {
  on_site_participant: '现场参会者',
  about: '游戏简介',
  looking_for_partners: '寻找合作伙伴',
  date: '日期',
  name: '标题',
  developer: '开发者',
  links_title: '其他链接',
  links_description: '添加您想要的资源链接',
  by_company: '公司 :company',
  request: '参加者信息',
  request_type: '查询类型',
  request_date: '查询日期',
  on_site_requests: '现场参会者',
  contest_participants: '参赛者',
  contest_participant: '参赛者',
  on_site: '现场',
  on_line: '仅限在线',
  profile: '参与者简介',
  showcase_info: 'If you’d like your game to be added to {developer_showcase}, please {contact} Otherwise, other attendees we’ll see it only when viewing your company profile.',
  showcase_info_let_us_know: 'let us know!',
  developer_showcase: 'Developer Showcase',
  min_votes_message: ':current votes received. Get another :diff to take part in the finals!'
}
