import { reactive, toRef } from 'vue'
import { Api } from '/-/plugins/api'
import ProcessorElastic from '/-/plugins/processor/processor-elastic'
import { Member, MemberLite } from '/~/models/member'
import { plainToClass } from '/-/plugins/helpers'
import { useEvents } from '/~/state/events'

const { eventId } = useEvents()

interface MembersStateInterface {
  list: ProcessorElastic | null
}

const state = reactive({
  list: null
}) as MembersStateInterface

function initProcessor(isPreviousMembersEnabled: boolean) {
  state.list = new ProcessorElastic({
    filter: {
      search: {
        type: String
      },
      region_id: {
        type: Array
      },
      sort: {
        type: String
      },
      features: {
        type: Array
      },
      look_id: {
        type: Array
      },
      offer_id: {
        type: Array
      },
      show_previous: {
        type: String,
        default: isPreviousMembersEnabled.toString()
      }
    },
    fetch: async (page, params, fetchParams) => {
      const data = await Api.fetch({
        url: `/${eventId.value}/members`,
        params: {
          sort: params.filter.sort || undefined,
          page,
          'filter[query]': params.filter.search || undefined,
          'filter[looking]': params.filter.look_id,
          'filter[features]': params.filter.features,
          'filter[offer]': params.filter.offer_id,
          'filter[geo]': params.filter.region_id,
          'filter[show_previous]': params.filter.show_previous === 'true' ? 1 : undefined,
        },
        ...fetchParams,
      })

      return data
    },
    mapping: (data) => plainToClass(data, MemberLite),
    allowCancel: true
  })
}

function resetProcessor() {
  state.list = null
}

async function getMember(id: number | string = 'me'): Promise<Member> {
  const { data } = await Api.fetch({
    url: `/${eventId.value}/members/${id}`,
  })

  return plainToClass(data, Member)
}

async function getMembersByIds(ids: number[]): Promise<Member[]> {
  const { data } = await Api.fetch({
    url: `/${eventId.value}/members`,
    params: {
      'filter[ids]': ids
    }
  }) as { data: any[] }

  return plainToClass(data, Member)
}

export function useMembers() {
  return {
    members: toRef(state, 'list'),
    initProcessor,
    resetProcessor,
    getMember,
    getMembersByIds
  }
}
