import { reactive, toRef } from 'vue'
import ProcessorElastic from '/-/plugins/processor/processor-elastic'
import { plainToClass, classToPlain } from '/-/plugins/helpers'
import { Api } from '/-/plugins/api'
import { Company, CompanyLite, CompanyAutocomplete } from '/~/models/company'
import { useEvents } from '/~/state/events'

const { eventId } = useEvents()

interface CompaniesStateInterface {
  companies: ProcessorElastic | null
}

const state = reactive({
  companies: null
}) as CompaniesStateInterface

function initProcessor(isPreviousCompaniesEnabled: boolean) {
  if (state.companies) { return }
  state.companies = new ProcessorElastic({
    filter: {
      search: {
        type: String
      },
      tags: {
        type: Array
      },
      business: {
        type: Array
      },
      show_previous: {
        type: String,
        default: isPreviousCompaniesEnabled.toString()
      }
    },
    fetch: async (page, params, fetchParams) => {
      const data = await Api.fetch({
        url: `/${eventId.value}/companies`,
        params: {
          page,
          'filter[query]': params.filter.search || undefined,
          'filter[business]': params.filter.business || undefined,
          'filter[tags]': params.filter.tags || undefined,
          show_previous: params.filter.show_previous === 'true' ? 1 : undefined,
        },
        ...fetchParams
      })

      return data
    },
    mapping: (data) => plainToClass(data, CompanyLite),
    allowCancel: true
  })
}

function resetProcessor() {
  state.companies = null
}

async function getCompany(id: number, params = {}): Promise<Company> {
  const { data } = await Api.fetch({
    url: `/${eventId.value}/companies/${id}`,
    ...params
  })

  return plainToClass(data, Company)
}

async function getCompanyList(ids: number[]): Promise<Company[]> {
  const { data } = await Api.fetch({
    url: `/${eventId.value}/companies`,
    params: {
      'filter[id]': ids
    }
  }) as { data:Company[] }

  // TODO: allow pagination
  return plainToClass(data, Company)
}

async function updateCompany(id: number, company: Partial<Company>) {
  const { data } = await Api.fetch({
    url: `/companies/${id}`,
    method: 'PUT',
    body: classToPlain(company)
  })

  return plainToClass(data, Company)
}

async function createCompany(company: Partial<Company>): Promise<Company> {
  const { data } = await Api.fetch({
    url: '/companies',
    method: 'POST',
    body: classToPlain(company)
  })

  return plainToClass(data, Company)
}

async function getCompanyAutocomplete(query: string): Promise<CompanyAutocomplete[]> {
  const { data } = await Api.fetch({
    url: '/companies/autocomplete',
    params: { query }
  }) as { data: any[] }

  return plainToClass(data, CompanyAutocomplete)
}

async function uploadMedia(
  companyId: number,
  file: File,
  category?: 'image' | 'logo' | 'background'
) {
  const { data } = await Api.fetch({
    url: `/companies/${companyId}/media`,
    method: 'POST',
    formData: {
      category: category || 'logo',
      media: file
    }
  })

  return plainToClass(data, Company)
}

export function useCompanies() {
  return {
    companies: toRef(state, 'companies'),
    uploadMedia,
    initProcessor,
    resetProcessor,
    getCompany,
    getCompanyList,
    updateCompany,
    createCompany,
    getCompanyAutocomplete,
  }
}
