import { Expose, Type } from 'class-transformer'
import { useProfile } from '/~/state/profile'
import { formatLinks } from '/-/plugins/format'
import { BaseModel } from './baseModel'
import dayjs from '@litvinovvo/dayjs'

export class MessageUser extends BaseModel {
  connectionStatus!: string
  isActive!: boolean
  isBlockedByMe!: boolean
  nickname!: string
  plainProfileUrl!: string
  profileUrl!: string
  role!: string
  userId!: string
}

export class Message extends BaseModel {
  channelType!: string
  channelUrl!: string
  customType!: string
  createdAt!: number
  errorCode!: number
  isOperatorMessage!: boolean
  mentionType!: string
  message!: string
  messageId!: number
  messageType!: string
  reqId!: string
  requestState!: string
  sendingStatus!: string
  data!: string

  @Expose({ name: '_sender' })
  @Type(() => MessageUser)
  sender!: null | MessageUser

  @Type(() => MessageUser)
  mentionedUsers!: [] | MessageUser[]

  get messageFormatted(): string {
    return this.message
      .trim()
      .replace(/[\r\n]+/g, '\n')
  }

  get id(): number {
    return this.messageId
  }

  get dataValue() {
    try {
      const data = JSON.parse(this.data)

      if (typeof data === 'object' && data.data) { return data.data }
      return this.data
    } catch {
      return this.data
    }
  }

  get messageHtml(): string {
    if (!this.message) {
      return ''
    }

    return formatLinks(this.message)
      .trim()
      .replace(/[\r\n]/g, '\n')
      .replace(/\n/g, '<br />')
  }

  get isAdmin(): boolean {
    return this.messageType === 'admin'
  }

  get isCompany(): boolean {
    return this.messageType === 'company'
  }

  get isPromo(): boolean {
    return this.messageType === 'promo'
  }

  get isMeAuthor(): boolean {
    const { profile } = useProfile()
    const senderId = !!this.sender && parseInt(this.sender.userId)

    return senderId === profile.value?.id || senderId === profile.value?.companyId
  }

  get time() {
    return this.createdAt ? dayjs(this.createdAt).format('HH:mm') : ''
  }

  get date() {
    return this.createdAt ? dayjs(this.createdAt).format('DD.MM.YYYY') : ''
  }

  get replayedUser(): MessageUser {
    return this.mentionedUsers[0]
  }

  get isEmoji(): boolean {
    const regexEmoji = /^(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])$/gmi
    const regexLines = /[\r\n]+/gm

    return regexEmoji.test(this.messageFormatted) && !regexLines.test(this.messageFormatted)
  }
}
