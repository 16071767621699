import SendBird, {
  BaseChannel,
  UserMessage,
  OpenChannel,
  GroupChannel,
  Member,
  SendBirdInstance,
  PreviousMessageListQuery,
  User,
  GroupChannelListQuery,
} from 'sendbird'
import { reactive, readonly, toRef, computed, ref, watch } from 'vue'
import { Message } from '/~/models/message'
import { MessageApi } from '/~/models/message-api'
import { Observer } from '/~/plugins/utils'
import { plainToClass } from '/-/plugins/helpers'
import { Api } from '/-/plugins/api'
import { useProfile } from '/~/state/profile'
import { useNotifications } from './notifications'
import { useLocale } from '/-/plugins/locale'
import { useEvents } from '/~/state/events'
import { useRoute } from './route'
import { useMembers } from '/~/state/members'
import { useCompanies } from './companies'
import { Storage } from '/-/plugins/storage/local'
import { dayjs } from '/~/plugins/datejs'
import { useConfig } from '/~/plugins/config'
import router from '/~/router'
import { Company } from '/~/models/company'
import type { PromoMessageInterface } from '/~/plugins/config'
import { removeTags } from '/-/plugins/format'
import { Member as HubMember } from '/~/models/member'

const { eventId } = useEvents()

interface SendBirdStateInterface {
  sendBird: SendBirdInstance
  previousMessageQuery?: PreviousMessageListQuery
  user?: User
  channel?: GroupChannel | OpenChannel | null
  listQuery?: GroupChannelListQuery
  connected: boolean
  unread: number
  channelStub?: {
    url: string
    type: string
  }
  channels: GroupChannel[]
  messages: (Message | UserMessage | MessageApi)[]
}

interface MessageInterface {
  message: string
  replay?: Message
  call?: any
  question?: boolean
}

interface EventInterface {
  messageDeleted?: (data: { channel: BaseChannel, messageId: number }) => void
  messageReceived?: (data: { channel: BaseChannel, messageId: number }) => void
  messageSend?: (data: UserMessage) => void
  messagesLoaded?: (data: { messages: UserMessage[], force: boolean }) => void
  channelEnter?: (data: OpenChannel) => void
}

const state: SendBirdStateInterface = reactive({
  sendBird: new SendBird({
    appId: `${import.meta.env.VITE_SENDBIRD_APP_ID}`
  }),
  previousMessageQuery: undefined,
  channels: [],
  messages: [],
  unread: 0,
  listQuery: undefined,
  channel: undefined,
  channelStub: undefined,
  user: undefined,
  connected: false
})

let previousMessageQuery: PreviousMessageListQuery
const observer = new Observer()

// promo messages variables
const PROMO_STORAGE_KEY = 'viewedPromoMessages'
const TIMER_LAG = 1000
const startTime = new Date()
const now = ref(new Date())
const promoTimers: number[] = []
const viewedPromoMessages = reactive(Storage.get(PROMO_STORAGE_KEY) || {})
const promoCompanies = reactive<{[key: string]: Company}>({})
const promoChannelIds = reactive<{[key: string]: string}>({})

watch(viewedPromoMessages, data => {
  Storage.set(PROMO_STORAGE_KEY, data)
})

// compute promoMessages on config or time update
const promoMessages = computed(() => {
  const { config } = useConfig()
  const messages = config.value.chatPrivate?.promoMessages || []
  const messagesToShow: PromoMessageInterface[] = []

  // clear timers for previous messages
  promoTimers.forEach(timer => clearTimeout(timer))
  promoTimers.length = 0

  messages.forEach(message => {
    // if message have no time or its time before now
    if (!message.time || getMessageTime(message).isBefore(dayjs(now.value))) {
      messagesToShow.push(message)
    } else {
      // else create timer to update time and rerun this function
      const timeToShow = getMessageTime(message).diff(dayjs(now.value)) + TIMER_LAG

      promoTimers.push(setTimeout(() => {
        now.value = new Date()
      }, timeToShow))
    }
  })
  return messagesToShow
})

function getPromoMessageByCompanyId(id: string) {
  return promoMessages.value.filter(message => message.companyId === +id)
    .sort((a, b) => {
      if (b.time && a.time) {
        return getMessageTime(a).diff(getMessageTime(b))
      } else if (!b.time && a.time) {
        return 1
      } else if (b.time && !a.time) {
        return -1
      } else {
        return 0
      }
    })
}

function getMessageTime(message: PromoMessageInterface) {
  const { event } = useEvents()

  return dayjs.tz(message.time, message.useEventTimezone ? event.value?.timezone : message.timezone)
}

// run on promoMessages or state.channels update
watch([promoMessages, () => state.connected], async () => {
  if (!state.connected) { return }
  const messages = promoMessages.value
  const { getCompany } = useCompanies()
  const { pushNotification } = useNotifications()
  const { getLocal } = useLocale()
  const { profile } = useProfile()

  for (let i = 0; i < messages.length; i++) {
    const message = messages[i]

    // if message with not cached company, get company data
    if (!promoCompanies[message.companyId]) {
      const company = await getCompany(message.companyId)

      // if company allowed to have chat and user is not in the company
      if (company.hasChat && profile.value?.companyId !== company.id) {
        promoCompanies[message.companyId] = company
      }
    }
  }

  // if have open chat
  if (state.channel && state.channel.isGroupChannel()) {
    const companyId = getChannelCompanyId(state.channel)

    if (companyId && promoCompanies[companyId]) { // if channel with promo messages
      const unreadMessageCount = getPromoMessageByCompanyId(companyId)
        .filter(message => !viewedPromoMessages[`${profile.value?.id}_${message.uid}`]).length

      if (unreadMessageCount) { // if get new promo messages
        injectPromoMessages()
        setPromoChannelViewed(state.channel)
      }
    }
  }

  // create notifications about new promo messages
  messages.filter(message =>
    !viewedPromoMessages[`${profile.value?.id}_${message.uid}`] && // not readed
    message.time && // has time
    dayjs(startTime).isBefore(getMessageTime(message))) // time comes after loading
    .forEach(message => {
      if (!promoCompanies[message.companyId]) { return }

      pushNotification({
        title: `${getLocal('notifications.new_message')} ${promoCompanies[message.companyId].name}`,
        icon: 'outline_chat_alt',
        theme: 'info',
        description: message.text,
        changeTitle: true,
        playSound: true,
        clickHandler: async () => {
          if (promoChannelIds[message.companyId]) {
            router.pushWithinEvent({ name: 'chats-view', params: { id: promoChannelIds[message.companyId] }})
          } else {
            router.pushWithinEvent({ name: 'chats-view', params: { id: await startPromoChat(message.companyId) }})
          }
        }
      })
    })
}, { immediate: true })

function setPromoChannelViewed(channel: GroupChannel) {
  const { profile } = useProfile()

  if (!state.channel || !state.channel.isGroupChannel()) {
    return
  }

  const channelCompanyId = getChannelCompanyId(state.channel)

  // get promo messages for channel and set readed
  promoMessages.value
    .filter(message => channelCompanyId && message.companyId === +channelCompanyId)
    .forEach(message => {
      viewedPromoMessages[`${profile.value?.id}_${message.uid}`] = true
    })

  // for case when you open promo channel by direct link and you need to set selected style
  // if channel selected and it is promo promo channel set url
  if (channelCompanyId && promoCompanies[channelCompanyId] && !promoChannelIds[channelCompanyId]) {
    promoChannelIds[channelCompanyId] = channel.url
  }
}

// if channel selected inject promo messages and sort them by time
function injectPromoMessages() {
  // @ts-expect-error hard to type correctly
  if (state?.channel?.channelType === 'open') {
    return
  }

  const channelCompanyId = state.channel?.data && JSON.parse(state.channel.data).company_id

  const promoMessages = channelCompanyId
    ? getPromoMessageByCompanyId(channelCompanyId).map(message => {
      return {
        message: removeTags(message.text),
        messageId: message.uid,
        channelType: 'group',
        messageType: 'promo',
        createdAt: message.time ? getMessageTime(message).valueOf() : null,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        _sender: {
          nickname: state.channel?.name,
          plainProfileUrl: state.channel?.coverUrl,
          userId: Number(channelCompanyId)
        }
      }
    })
    : []

  state.messages = [
    ...plainToClass(promoMessages, Message),
    ...state.messages.filter(message => message.messageType !== 'promo')
  ]

  state.messages.sort((a, b) => (b.createdAt || 0) > (a.createdAt || 0) ? -1 : 1)
}

async function startPromoChat(companyId: number) {
  const url = await startCompanyChat(companyId)

  promoChannelIds[companyId] = url

  return url
}

function getChannelCompanyId(channel: GroupChannel) {
  return channel.data && String(JSON.parse(channel.data).company_id)
}

const allChannels = computed(() => {
  const { profile } = useProfile()
  // create promo channels
  const promoChannels = Object.keys(promoCompanies)
    .filter(companyId => !state.channels // get companies that
      .filter(channel => channel.lastMessage)
      .find(channel => getChannelCompanyId(channel) === companyId)) // not in the list with not empty channels
    .map(companyId => {
      const lastMessages = getPromoMessageByCompanyId(companyId)
      const unreadCount = lastMessages.filter(message => !viewedPromoMessages[`${profile.value?.id}_${message.uid}`]).length
      const lastPromoMessage = lastMessages[lastMessages.length - 1]

      return {
        name: promoCompanies[companyId].name,
        customType: 'company',
        data: JSON.stringify({ company_id: companyId }),
        url: promoChannelIds[companyId] || null,
        coverUrl: promoCompanies[companyId].avatarUrl,
        unreadMessageCount: unreadCount,
        lastMessage: getLastMessageObject(lastPromoMessage)
      }
    })

  // update last message in channel with promo messages and normal messages
  state.channels
    .filter(channel => channel.lastMessage) // get not empty channels
    .forEach(channel => {
      const channelCompanyId = getChannelCompanyId(channel)

      if (channelCompanyId) {
        const promoMessages = getPromoMessageByCompanyId(channelCompanyId)

        // if channel with promo messages
        if (promoCompanies[channelCompanyId] && promoMessages.length > 0) {
          const lastPromoMessage = promoMessages[promoMessages.length - 1]

          channel.unreadMessageCount = promoMessages.filter(message => !viewedPromoMessages[`${profile.value?.id}_${message.uid}`]).length

          // if promo message last
          if (channel.lastMessage && channel.lastMessage.createdAt <= getMessageTime(lastPromoMessage).valueOf()) {
            channel.lastMessage = getLastMessageObject(lastPromoMessage) as UserMessage
          }
        }
      }
    })

  return (promoChannels as GroupChannel[]).concat(state.channels)
})

function getLastMessageObject(promoMessage: PromoMessageInterface) {
  return {
    message: stripTags(promoMessage.text),
    createdAt: promoMessage.time ? getMessageTime(promoMessage).valueOf() : null,
  }
}

function stripTags(data: string) {
  return data.replace(/<[^>]*>?/gm, '')
}

const unreadTotal = computed(() => {
  const { profile } = useProfile()

  return state.unread + promoMessages.value.filter(message => !viewedPromoMessages[`${profile.value?.id}_${message.uid}`] && promoCompanies[message.companyId]).length
})

function getUserId() {
  const { profile } = useProfile()

  return profile.value?.id.toString(10)
}

function getMember(channel: GroupChannel): Member | undefined {
  let member: Member[] = []

  if (channel && state.connected) {
    member = channel.members.filter(value => {
      return value?.userId !== state.user?.userId
    })
  }

  return member[0]
}

function getChannelAvatar(channel: GroupChannel): string | undefined {
  if (channel.customType === 'company') {
    return channel.coverUrl
  }

  return getMember(channel)?.profileUrl
}

function getChannelName(channel: GroupChannel): string | undefined {
  if (channel.customType === 'company') {
    return channel.name
  }

  return getMember(channel)?.nickname
}

function getChannelUserId(channel: GroupChannel): number | undefined {
  if (channel.customType === 'company') {
    return
  }
  const userId = getMember(channel)?.userId

  if (!userId) {
    return
  }
  return parseInt(userId)
}

/**
 * Возвращает объект, описывающий соответствие между url чатов и профилями пользователей, с которыми ведутся данные чаты.
 * Примечане: если пользователь не является участником текущей конференции, то объект профиля для него возвращен не будет.
 */
async function getChannelsMembersData(channels: GroupChannel[]): Promise<{[channelUrl: string]: HubMember} | undefined> {
  if (!channels) {
    return
  }
  const { getMembersByIds } = useMembers()

  const channelUrls: Record<number, string> = {}
  const userIds: number[] = []

  channels.forEach(channel => {
    const userId = getChannelUserId(channel)

    if (!userId) {
      return
    }
    userIds.push(userId)
    channelUrls[userId] = channel.url
  })

  if (!userIds || !userIds.length) {
    return
  }

  const profiles = await getMembersByIds(userIds)

  return profiles.reduce((acc: Record<string, HubMember>, curr) => {
    const channelUrl = channelUrls[curr.user.id]

    acc[channelUrl] = curr
    return acc
  }, {})
}

// we don't put any OpenChannels in state.channels so we don't need to use argument "type: 'open' | 'group'"
function getChatChannels(next = false) {
  if (!state.listQuery || !next) {
    state.listQuery = state.sendBird.GroupChannel.createMyGroupChannelListQuery()
    state.listQuery.order = 'latest_last_message'
    state.listQuery.customTypesFilter = ['private', 'company']
  }

  return new Promise((resolve, reject) => {
    state.listQuery?.next((openChannels, error) => {
      if (error) {
        return reject(error)
      }

      if (next) {
        state.channels = [
          ...state.channels,
          ...openChannels
        ]
      } else {
        state.channels = openChannels
      }

      resolve(openChannels)
    })
  })
}

function getUnreadChannelsCount() {
  return new Promise((resolve, reject) => {
    state.sendBird.getTotalUnreadMessageCount((count, error) => {
      if (error) {
        return reject(error)
      }

      state.unread = count

      resolve(count)
    })
  })
}

function openGroupChannel(channelUrl: string): Promise<GroupChannel> {
  return new Promise((resolve, reject) => {
    const openChannel = state.channels.find(({ url }) => url === channelUrl)

    if (openChannel) {
      state.channel = openChannel
      setPromoChannelViewed(openChannel)

      return resolve(openChannel)
    }

    state.sendBird.GroupChannel.getChannel(channelUrl, (openChannel, error) => {
      if (error) {
        return reject(error)
      }

      state.channel = openChannel
      setPromoChannelViewed(openChannel)

      resolve(openChannel)
    })
  })
}

function openGroupChannelByUrl(url: string) {
  state.channel = state.channels.find((channel) => channel.url === url)
}

async function startGroupChannelByUserId(id: number) {
  await connectChat()

  const chat = state.channels.find(({ members }) => members.find(({ userId }) => Number(userId) === id))

  if (chat) {
    return chat.url
  } else {
    return await startChat(id)
  }
}

function clearSelectedChannel() {
  state.channel = null
}

async function openChannelByUrl(channelUrl: string): Promise<OpenChannel> {
  return new Promise((resolve, reject) => {
    if (!state.connected) {
      return reject(new Error('SendBird is not connected'))
    }

    state.sendBird.OpenChannel.getChannel(channelUrl, (openChannel, error) => {
      if (error) {
        console.error(error)

        return reject(error)
      }

      state.channel = openChannel

      state.channel.enter((response, error) => {
        if (error) {
          return reject(error)
        }

        observer.broadcast('channel-enter', openChannel)
      })

      resolve(openChannel)
    })
  })
}

function clearMessages() {
  state.messages = []
}

function addMessage(message: Message) {
  if (state.messages.length >= 50) {
    state.messages.shift()
  }

  // basechat reload messages when app comes visible, at the same time
  // browser unfreezes callbacks with new messages, that could create duplicates
  if (state.messages.some(listMessage => listMessage.messageId === message.messageId)) {
    return
  }

  state.messages.push(message)
}

async function connectChat() {
  const { getProfileChatToken } = useProfile()
  let token = ''
  const userId = getUserId()

  if (!userId || state.connected || !import.meta.env.VITE_SENDBIRD_APP_ID) {
    return
  }

  try {
    token = await getProfileChatToken()
  } catch (err) {}

  return new Promise((resolve, reject) => {
    state.sendBird.connect(userId, token, async (user, error) => {
      if (error) {
        return reject(error)
      }

      state.user = user
      state.connected = true

      await Promise.all([getUnreadChannelsCount(), getChatChannels()])

      resolve(user)
    })

    state.sendBird.addChannelHandler('onMessageDeleted', onMessageDeleted())
    state.sendBird.addChannelHandler('onMessageReceived', onMessageReceived())
  })
}

async function disconnectChat() {
  return new Promise((resolve, reject) => {
    if (!state.connected) {
      resolve(Promise.resolve())
    }

    state.sendBird.disconnect((data, error) => {
      if (error) {
        return reject(error)
      }

      state.connected = false

      resolve(data)
    })
  })
}

async function loadMessageList(force = false, clear = false) {
  if (!state.channel) {
    return false
  }

  if (force || !previousMessageQuery) {
    previousMessageQuery = state.channel.createPreviousMessageListQuery()
    previousMessageQuery.includeMetaArray = true

    state.previousMessageQuery = previousMessageQuery
  }

  return new Promise((resolve, reject) => {
    previousMessageQuery.load(50, false, '', (messageList, error) => {
      if (error) {
        return reject(error)
      }

      state.messages = [
        ...plainToClass(messageList, Message),
        ...(clear ? [] : state.messages),
      ]

      injectPromoMessages()

      observer.broadcast('messages-loaded', { messages: messageList, force })

      resolve(messageList)
    })
  })
}

async function sendMessage(data?: MessageInterface) {
  if (!state.connected) {
    return Promise.reject(new Error('Chat not connected'))
  }

  if (!state.channel) {
    return Promise.reject(new Error('Chat not selected'))
  }

  const params = new state.sendBird.UserMessageParams()

  params.message = removeTags(data?.message || '')
  params.mentionType = 'users'

  let messageData: any = { event_id: eventId.value || '' }

  if (data?.replay?.sender) {
    messageData = { ...messageData, data: data.replay.message }
    params.customType = 'replay'
    params.mentionedUserIds = [data.replay.sender.userId]
  }

  if (data?.call) {
    params.customType = 'call'
    messageData = { ...messageData, data: data.call }
  }

  if (data?.question) {
    params.customType = 'question'
  }

  params.data = JSON.stringify(messageData)

  return new Promise((resolve, reject) => {
    if (!state.channel) {
      return reject(new Error('state.channel is undefined'))
    }

    state.channel.sendUserMessage(params, async (message, error) => {
      if (error) {
        return reject(error)
      }

      if (!state.channels.some((channel) => channel.url === state?.channel?.url)) {
        await getChatChannels()
      }

      addMessage(plainToClass(message, Message))
      observer.broadcast('message-send', message)
      state.channels.sort((channelA, channelB) =>
        (channelB.lastMessage?.createdAt || 0) - (channelA.lastMessage?.createdAt || 0))
      resolve(message)
    })
  })
}

async function deleteMessage(message: Message) {
  if (!message.isMeAuthor) {
    const url = state.channel?.url || state.channelStub?.url

    return await Api.fetch({
      url: `/${eventId.value}/chat/delete-message/${url}/${message.id}`,
      method: 'POST',
      params: { event_id: eventId.value }
    })
  }

  // we use Message class because need our own methods, but sendbird requires UserMessage
  return new Promise((resolve, reject) => {
    if (!state.channel) {
      return reject(new Error('state.channel is undefined'))
    }

    state.channel.deleteMessage(message as unknown as UserMessage, (response, error) => {
      if (error) {
        return reject(error)
      }

      resolve(response)
    })
  })
}

async function getMessageById(messageId: string) {
  const params = new state.sendBird.MessageListParams()

  return new Promise((resolve, reject) => {
    if (!state.channel) {
      return reject(new Error('state.channel is undefined'))
    }

    state.channel.getMessagesByMessageId(parseInt(messageId), params, (messages, error) => {
      if (error) {
        return reject(error)
      }

      resolve(messages)
    })
  })
}

async function startChat(id: number) {
  const { data } = await Api.fetch({
    url: `/${eventId.value}/chat/start/${id}`,
    params: { event_id: eventId.value }
  })

  return data.url
}

async function fetchChat(channel: string) {
  const data = await Api.fetch({
    url: `/${eventId.value}/chat/messages/${channel}`
  }) as any[]

  state.channelStub = {
    type: 'open',
    url: channel,
  }
  state.messages = [
    ...plainToClass(data, MessageApi)
  ]
}

async function muteUser(userId: string) {
  const url = state.channel?.url || state.channelStub?.url

  return await Api.fetch({
    url: `/${eventId.value}/chat/mute-user/${url}/${userId}`,
    method: 'POST',
    params: { event_id: eventId.value }
  })
}

async function unmuteUser(userId: string) {
  const url = state.channel?.url || state.channelStub?.url

  return await Api.fetch({
    url: `/${eventId.value}/chat/unmute-user/${url}/${userId}`,
    method: 'POST',
    params: { event_id: eventId.value }
  })
}

async function startCompanyChat(companyId: number) {
  const { data } = await Api.fetch({
    url: `/${eventId.value}/chat/start-company/${companyId}`,
    params: { event_id: eventId.value }
  })

  return data.url
}

function onMessageDeleted() {
  const channelHandler = new state.sendBird.ChannelHandler()

  channelHandler.onMessageDeleted = (channel, messageId) => {
    const idx = state.messages.findIndex((message) => message.messageId === messageId)

    if (idx > -1) {
      state.messages.splice(idx, 1)
    }

    getChatChannels()
    getUnreadChannelsCount()

    observer.broadcast('message-deleted', { channel, messageId })
  }

  return channelHandler
}

function onMessageReceived() {
  const { pushNotification } = useNotifications()
  const { getLocal } = useLocale()
  const channelHandler = new state.sendBird.ChannelHandler()

  channelHandler.onMessageReceived = (channel, message) => {
    const msg = plainToClass(message, Message)

    if (channel.url === state.channel?.url) {
      addMessage(msg)
    }
    const isChannelSelected = state.channel && channel.url === state.channel.url

    const route = useRoute()

    if (!msg.isMeAuthor && msg.channelType === 'group' && (route.name !== 'chats-view' || !isChannelSelected)) {
      pushNotification({
        title: `${getLocal('notifications.new_message')} ${msg.sender?.nickname}`,
        icon: 'outline_chat_alt',
        theme: 'info',
        description: msg.message,
        changeTitle: true,
        playSound: true,
        clickHandler: () => {
          router.pushWithinEvent({ name: 'chats-view', params: { id: msg.channelUrl }}).then()
        }
      })
    }
    getChatChannels().then()
    getUnreadChannelsCount().then()

    observer.broadcast('message-received', { channel, message })
  }

  return channelHandler
}

async function report(eventId: number, blockUserId: string, msg: string) {
  return await Api.fetch({
    url: `/${eventId}/members/block-user-chat/${blockUserId}`,
    method: 'POST',
    body: {
      msg
    }
  })
}

export function useChat() {
  return {
    user: readonly(toRef(state, 'user')),
    connected: readonly(toRef(state, 'connected')),
    messages: readonly(toRef(state, 'messages')),
    channels: readonly(allChannels),
    unread: readonly(unreadTotal),
    listQuery: readonly(toRef(state, 'listQuery')),
    previousMessageQuery: readonly(toRef(state, 'previousMessageQuery')),
    startChat,
    startCompanyChat,
    startPromoChat,
    getMember,
    startGroupChannelByUserId,
    openGroupChannelByUrl,
    getChannelAvatar,
    getChannelName,
    getChannelUserId,
    getChannelsMembersData,
    getMessageById,
    getUnreadChannelsCount,
    clearMessages,
    loadMessageList,
    sendMessage,
    openGroupChannel,
    openChannelByUrl,
    clearSelectedChannel,
    deleteMessage,
    connectChat,
    disconnectChat,
    fetchChat,
    muteUser,
    unmuteUser,
    getChatChannels,
    report
  }
}

export function useChatObserver() {
  return {
    unsubscribe() {
      observer.unsubscribe()
    },
    onMessageDeleted(callback: EventInterface['messageDeleted']) {
      if (!callback) { return }
      observer.subscribe('message-deleted', callback)
    },
    onMessageReceived(callback: EventInterface['messageReceived']) {
      if (!callback) { return }
      observer.subscribe('message-received', callback)
    },
    onMessageSend(callback: EventInterface['messageSend']) {
      if (!callback) { return }
      observer.subscribe('message-send', callback)
    },
    onMessagesLoaded(callback: EventInterface['messagesLoaded']) {
      if (!callback) { return }
      observer.subscribe('messages-loaded', callback)
    },
    onChannelEnter(callback: EventInterface['channelEnter']) {
      if (!callback) { return }
      observer.subscribe('channel-enter', callback)
    },
  }
}
