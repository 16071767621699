<template>
  <main class="relative h-screen -mt-14 flex overflow-hidden">
    <div class="bg-white flex flex-1 flex-col content-center justify-center pt-14 dark:bg-trueGray-700 dark:text-white">
      <div class="my-3 text-center">
        <h1 class="leading-10 text-3xl font-medium dark:text-white mb-4">
          {{ getLocal('errors.no_network_title') }}
        </h1>
        <p class="mb-4 text-sm dark:text-gray-100">
          {{ getLocal('errors.no_network_text') }}
        </p>
        <BaseButton
          size="sm"
          @click="update">
          {{ getLocal('errors.no_network_button') }}
        </BaseButton>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useLocale } from '/-/plugins/locale'
import BaseButton from '/-/components/button/base-button.vue'

export default defineComponent({
  name: 'NetworkError',
  components: {
    BaseButton,
  },
  props: {
    to: {
      type: String,
      default: '/'
    },
  },
  setup() {
    const { getLocal } = useLocale()

    return {
      getLocal
    }
  },
  methods: {
    update() {
      this.$router.replace(this.to)
    }
  }
})
</script>
