<template>
  <div v-if="loading"
       class="h-screen w-full flex items-center justify-center">
    <BaseSpinner class="mx-auto" />
  </div>
  <RootRouterView v-else />
</template>

<script lang="ts">
import { defineComponent, WatchStopHandle } from 'vue'
import { useLocale } from '/-/plugins/locale'
import { useEchoObserver } from '/~/plugins/echo'
import BaseSpinner from '/-/components/spinner/base-spinner.vue'
import RootRouterView from '/~/router/router-view-wrap.vue'
import { useAppEvent } from '/~/plugins/app-event'
import { START_LOCATION } from 'vue-router'
import { Routes } from '/~/router/types'
import { EventSubscriptionInterface } from '/-/plugins/helpers/observer'

export default defineComponent({
  name: 'App',
  components: {
    BaseSpinner,
    RootRouterView
  },
  data() {
    return {
      loading: true,
      unwatchLanguage: null as null | WatchStopHandle,
      onConfigUpdateSubscriber: undefined as undefined | EventSubscriptionInterface,
    }
  },
  watch: {
    '$router.currentRoute.value':
    {
      async handler(newRoute, oldRoute) {
        const { switchEvent } = useAppEvent()
        const newRouteEvent = newRoute?.params?.eventSlug
        const oldRouteEvent = oldRoute?.params?.eventSlug
        const errorPages = [Routes.NetworkError, Routes.NotFound, Routes.ServerError]

        try {
          this.loading = true
          if (newRouteEvent !== oldRouteEvent && oldRoute !== START_LOCATION && !errorPages.includes(newRoute.name)) {
            await switchEvent(newRouteEvent || undefined)
          } else if (oldRoute === START_LOCATION || errorPages.includes(oldRoute.name)) {
            await this.init()
          }
        } finally {
          this.loading = false
        }
      },
      deep: true
    }
  },
  methods: {
    async init() {
      const { lang } = useLocale()

      const { onConfigUpdate } = useEchoObserver()
      const { switchEvent } = useAppEvent()

      const currentRoute = this.$router.currentRoute.value
      const eventSlug = currentRoute?.params?.eventSlug || currentRoute?.redirectedFrom?.params?.eventSlug

      await switchEvent(typeof eventSlug === 'string' ? eventSlug : undefined)

      if (this.unwatchLanguage) {
        this.unwatchLanguage()
      }
      this.unwatchLanguage = this.$watch(() => lang.value, () => {
        if (this.loading) { return }
        location.reload()
      })

      if (this.onConfigUpdateSubscriber) {
        this.onConfigUpdateSubscriber.unsubscribe()
      }

      this.onConfigUpdateSubscriber = onConfigUpdate(async (data: {event_id: number}) => {
        switchEvent(data.event_id)
      })
    },
  }
})
</script>
