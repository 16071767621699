import { BaseModel } from '/~/models/baseModel'
import { Exclude, Expose, Transform, TransformFnParams } from 'class-transformer'
import { Media } from '/~/models/media'
import marked from 'marked'
import { removeTags } from '/-/plugins/format'
import { DictionaryItemInterface } from '/~/models/dictionary'

const urlPattern = /(\b(https?):\/\/|\b(www\.))([-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])(\.\S+)/im

export interface GameCompanyInterface {
  id: number,
  name: string
}

export interface GameLinkInterface {
  url: string
  name?: string
}

export const enum GameMediaCategory {
  Icon = 'icon',
  Cover = 'cover',
  Photo = 'photo',
}

function getDictionariesValues({ value }: TransformFnParams): number[] {
  return value.map((item: DictionaryItemInterface) => item.value)
}

export class GameLite extends BaseModel {
  id?: number
  name = ''

  @Expose({ name: 'dev_start_at' })
  devStartAt = ''

  @Expose({ name: 'release_at' })
  releaseAt = ''

  @Exclude({ toPlainOnly: true })
  company?: GameCompanyInterface

  @Exclude({ toPlainOnly: true })
  icon?: Media

  @Transform(getDictionariesValues, { toPlainOnly: true })
  engines: DictionaryItemInterface[] = []

  @Transform(getDictionariesValues, { toPlainOnly: true })
  genres: DictionaryItemInterface[] = []

  @Transform(getDictionariesValues, { toPlainOnly: true })
  platforms: DictionaryItemInterface[] = []

  @Expose({ name: 'looking_for' })
  @Transform(getDictionariesValues, { toPlainOnly: true })
  lookingFor: DictionaryItemInterface[] = []
}

export class Game extends GameLite {
  description = ''
  links: GameLinkInterface[] = []
  video = ''

  @Exclude({ toPlainOnly: true })
  cover?: Media

  @Exclude({ toPlainOnly: true })
  photos: Media[] = []

  @Expose({ name: 'looking_for_partner' })
  lookingForPartner!: boolean

  @Transform(getDictionariesValues, { toPlainOnly: true })
  monetizations: DictionaryItemInterface[] = []

  @Transform(getDictionariesValues, { toPlainOnly: true })
  stages: DictionaryItemInterface[] = []

  get stage() {
    return this.stages?.[0]
  }

  set stage(newValue) {
    this.stages = [newValue]
  }

  get descriptionMarked(): string {
    return marked(removeTags(this.description)).replace(/<a href=/g, '<a target="_blank" href=')
  }

  get processedLinks() {
    const validLinks = (this.links || []).filter(({ url }) => urlPattern.test(url))

    return validLinks.map((data) => {
      const url = data.url
        .replace(urlPattern, (match, p1) => {
          if (p1 === 'www.') {
            match = 'http://' + match
          }

          return match
        })

      return {
        ...data,
        url
      }
    })
  }
}
