import { Type, Expose } from 'class-transformer'
import { BaseModel } from './baseModel'
import { User, UserLite } from './user'
import { Ticket } from './ticket'

export class MemberLite extends BaseModel {
  uid!: string

  @Expose({ name: 'ticket_type' })
  ticketType!: number

  @Expose({ name: 'is_sponsor' })
  isSponsor!: boolean

  @Expose({ name: 'is_speaker' })
  isSpeaker!: boolean

  @Expose({ name: 'is_business' })
  isBusiness!: boolean

  @Expose({ name: 'is_organizer' })
  isOrganizer!: boolean

  @Type(() => UserLite)
  user!: UserLite

  get company() { return this.user.company }
}
export class Member extends MemberLite {
  @Expose({ name: 'can_meeting' })
  canMeeting!: boolean

  @Expose({ name: 'can_offline_meeting' })
  canOfflineMeeting!: boolean

  @Expose({ name: 'can_upgrade' })
  canUpgrade!: boolean

  @Expose({ name: 'can_vote' })
  canVote!: boolean

  @Expose({ name: 'can_chat' })
  canChat!: boolean

  @Expose({ name: 'role_id' })
  roleId!: number

  @Expose({ name: 'employee_roles' })
  employeeRoles!: string[]

  @Type(() => User)
  user!: User

  @Type(() => Ticket)
  ticket!: Ticket
}
