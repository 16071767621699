import { App } from 'vue'
import { useAuth } from '/-/plugins/auth'
import { useAuthToken } from '/~/plugins/auth-token'
import { useLogout } from '/~/plugins/logout'
import { useWebFont } from '/~/plugins/web-font'
import router from '/~/router'

async function setAuth() {
  const { setToken } = useAuth()
  const { getToken } = useAuthToken()
  const { watchTabsLogout } = useLogout()

  const token = await getToken()

  if (token) {
    setToken(token)
  }
  watchTabsLogout()
}

export async function bootstrap(app: App<Element>) {
  const { createWebFont } = useWebFont()

  await setAuth()
  try {
    await createWebFont()
  } catch (error: any) {
    console.warn('Error on webfont loading', error)
  }

  return {
    app,
    router
  }
}
