export const Routes = {
  Home: 'home',
  ServerError: 'server-error',
  NetworkError: 'network-error',
  MembersList: 'members-list',
  NotFound: '404',
  Event: 'event',
}

export const EVENT_ROUTE_PREFIX = 'event-'

export type RouteNames = keyof typeof Routes

export const EventRoutes = (Object.keys(Routes) as RouteNames[]).reduce((routes, route) => {
  routes[route] = `${EVENT_ROUTE_PREFIX}${Routes[route]}`
  return routes
}, {} as Record<RouteNames, string>)

export interface RouterProps {
  params: {
    id: string
  }
}

export interface RouterGameProps {
  params: {
    id: string
    gameId: string
  }
}

export interface RouterLectureProps {
  params: {
    id: string
    lectureId: string
  }
}

declare module 'vue-router' {
  interface RouteMeta {
    title?: string
    rootPage?: boolean
    fullscreen?: boolean
    isForbiddenOnGeneral?: boolean
    saveScrollPosition?: boolean
    allowAuthorized?: boolean
    guest?: boolean
  }
}
