export default {
  create: '创建会议',
  create_online: '创建线上会议',
  is_online: '在线会议',
  agenda: '议程',
  date: '日期',
  time_slot: '时段',
  time: '所用时间',
  request: '请求',
  participants: '参与者',
  cancel: '取消',
  meetings: '会议',
  meeting: '会议',
  join_meeting: '视频会议',
  calendar: '日历',
  select_timeslot_prompt: '选择时间',
  today: '今日',
  invites: '邀请',
  upcoming: '即将到来',
  location: '地点',
  online: '线上',
  onsite: '线下',
  invited: '已邀请',
  accepted: '已接受',
  rejected: '已拒绝',
  cancel_verb: '取消',
  reschedule: '重新计划',
  send_message: '发送消息',
  reject: '拒绝',
  accept: '接受',
  reason: '原因',
  cancel_meeting: '取消会议',
  save: '保存',
  day: '天',
  days: '天',
  week: '周',
  time_slot_locked_message: `您锁定了这个时段。
  在您的日程安排中解锁该时段，或将此会议重新安排到另一个时段。`,
  time_slot_busy_message: `您在本时间已安排了一个会议。
  将这次会议重新安排到另一个时段。`,
  no_meetings: '没有会议',
  status_waiting: '发送邀请',
  status_declined: '已谢绝',
  status_accepted: '已接受',
  status_incoming: '传入邀请',
  unlock: '解锁',
  lock: '锁定',
  location_hint_text: '游览上海展区，请查看',
  location_hint_link: '会场平面图'
}
