import { useTabEvents } from '/-/plugins/tab-events'
import { EventSubscriptionInterface } from '/-/plugins/helpers/observer'
import { useAuth } from '/-/plugins/auth'
import { useAuthToken } from '/~/plugins/auth-token'
import { useProfile } from '/~/state/profile'
import { useShowcaseRequests } from '/~/state/showcase-requests'
import router from '/~/router'

const LOGOUT_EVENT = 'logout'
let subscriber: null | EventSubscriptionInterface = null

function watchTabsLogout() {
  const { addListener } = useTabEvents()

  if (subscriber) { subscriber.unsubscribe() }

  subscriber = addListener(LOGOUT_EVENT, async () => {
    await logoutCurrentTab()
    router.replaceWithinEvent({ name: 'auth-login' })
  })
}

async function logoutCurrentTab() {
  const { logout } = useAuth()
  const { removeProfile } = useProfile()
  const { removeToken } = useAuthToken()
  const { clearShowcaseMeta } = useShowcaseRequests()

  await logout()
  await removeProfile()
  removeToken()
  clearShowcaseMeta()
}

async function logoutAllTabs() {
  const { triggerEvent } = useTabEvents()

  await logoutCurrentTab()
  triggerEvent(LOGOUT_EVENT)
}

export function useLogout() {
  return {
    logout: logoutAllTabs,
    watchTabsLogout,
  }
}
