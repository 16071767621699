import { Plugin } from 'vue'
import { App } from '@capacitor/app'
import { StatusBar, Style } from '@capacitor/status-bar'
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications'
import { Api } from '/-/plugins/api'

const plugin: Plugin = async function(_app, options) {
  // StatusBar
  StatusBar.setStyle({ style: Style.Dark }).then()
  StatusBar.setBackgroundColor({ color: '#3b82f6' }).then()

  // Navigation
  App.addListener('backButton', () => {
    if (options.router) {
      options.router.go(-1)
    }
  })

  App.addListener('appUrlOpen', (data) => {
    const slug = data.url.split('.app').pop()

    if (slug && options.router) {
      options.router.pushWithinEvent({ path: slug })
    }
  })

  // Notifications
  let pushNotificationRequested = false

  // Request permission to use push notifications
  // iOS will prompt user and return if they granted permission or not
  // Android will just grant without prompting
  PushNotifications.requestPermissions().then(result => {
    if (result.receive === 'granted') {
      // Register with Apple / Google to receive push via APNS/FCM
      if (!pushNotificationRequested) {
        PushNotifications.register()
        pushNotificationRequested = true
      }
    } else {
      // Show some error
    }
  })

  // On success, we should be able to receive notifications
  PushNotifications.addListener('registration',
    (token: Token) => {
      console.log('Push registration success, token: ' + token.value)
      Api.fetch({
        url: '/users/me/push',
        method: 'POST',
        body: {
          token: token.value
        }
      })
    }
  )

  // Some issue with our setup and push will not work
  PushNotifications.addListener('registrationError',
    (error: any) => {
      console.log('Error on registration: ' + JSON.stringify(error))
    }
  )

  // Show us the notification payload if the app is open on our device
  PushNotifications.addListener('pushNotificationReceived',
    (notification: PushNotificationSchema) => {
      console.log('Push received: ' + JSON.stringify(notification))
    }
  )

  // Method called when tapping on a notification
  PushNotifications.addListener('pushNotificationActionPerformed',
    (notification: ActionPerformed) => {
      console.log('Push action performed: ' + JSON.stringify(notification))
    }
  )
}

export default plugin
