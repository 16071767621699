export default {
  about: 'About Game',
  looking_for_partners: 'Looking For Partners',
  date: 'Date',
  name: 'Title',
  developer: 'Developer',
  links_title: 'Additional links',
  links_description: 'Add links to the resources you want',
  by_company: 'by :company',
  request: 'Participant Info',
  request_type: 'Request Type',
  request_date: 'Request Date',
  on_site_requests: 'On-site Participants',
  contest_participants: 'Contest Participants',
  on_site_participant: 'On-Site Participant',
  contest_participant: 'Contest Participant',
  on_site: 'On-Site',
  on_line: 'Online Only',
  profile: 'Participant Profile',
  showcase_info: 'If you’d like your game to be added to {developer_showcase}, please {contact} Otherwise, other attendees we’ll see it only when viewing your company profile.',
  showcase_info_let_us_know: 'let us know!',
  developer_showcase: 'Developer Showcase',
  min_votes_message: ':current votes received. Get another :diff to take part in the finals!'
}
