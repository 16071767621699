import { h, resolveComponent } from 'vue'
import { RouteRecordRaw } from 'vue-router'
import NetworkError from '/~/views/errors/network-error.vue'
import { useApplication } from '/~/state/application'
import { Routes, RouterProps, RouterGameProps, RouterLectureProps, EVENT_ROUTE_PREFIX } from './types'

const { isGeneral } = useApplication()

const emptyRouteComponent = {
  render: () => h(resolveComponent('router-view')),
}

const commonRoutes: RouteRecordRaw[] = [
  {
    path: '',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('../views/expo/expo.vue'),
        meta: {
          title: 'Home',
          rootPage: true,
          fullscreen: true
        }
      },
      {
        path: 'expo',
        name: 'expo',
        component: () => emptyRouteComponent,
        redirect: { name: 'home' },
        children: []
      },
    ]
  },
  {
    path: 'program',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'program',
        component: () => import('../views/program/program-view.vue'),
        meta: {
          title: 'Program',
          rootPage: true,
          isForbiddenOnGeneral: true
        }
      }
    ]
  },
  {
    path: 'frame',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: ':id?',
        name: 'frame',
        component: () => import('../views/frame/frame-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: params.id
        }),
        meta: {
          title: 'Frame',
          rootPage: true,
          isForbiddenOnGeneral: true
        }
      }
    ]
  },
  {
    path: 'contacts',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'contacts',
        component: () => import('../views/contacts/contacts-view.vue'),
        meta: {
          title: 'Contacts',
          rootPage: true
        }
      }
    ]
  },
  {
    path: 'stub',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: ':id?',
        name: 'stub',
        component: () => import('../views/stub/stub-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: params.id
        }),
        meta: {
          title: 'Stub',
          rootPage: true,
          isForbiddenOnGeneral: true
        }
      }
    ]
  },
  {
    path: 'partyspace',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'partyspace',
        component: () => import('../views/partyspace/partyspace-view.vue'),
        meta: {
          title: 'Party Space',
          rootPage: true,
          isForbiddenOnGeneral: true
        }
      }
    ]
  },
  {
    path: 'lectures',
    component: () => import('../layouts/main/main-entry.vue'),
    meta: {
      isForbiddenOnGeneral: true
    },
    children: [
      {
        path: ':id/:lectureId?',
        name: 'lectures',
        component: () => import('../views/lectures/lectures-root.vue'),
        props: ({ params }: RouterLectureProps) => ({
          id: params.id,
          lectureId: params.lectureId
        }),
        children: [
          {
            path: '',
            name: 'lectures-main',
            component: () => import('../views/lectures/lectures-main.vue'),
            props: ({ params }: RouterLectureProps) => ({
              id: params.id,
              lectureId: params.lectureId
            }),
            meta: {
              title: 'Lectures',
              rootPage: true
            }
          }
        ]
      }
    ]
  },
  {
    path: 'lectures-archive',
    component: () => import('../layouts/main/main-entry.vue'),
    meta: {
      isForbiddenOnGeneral: true
    },
    children: [
      {
        path: '',
        name: 'lectures-archive',
        component: () => import('../views/lectures/lectures-archive-list.vue'),
        meta: {
          title: 'Archive of lectures',
          rootPage: true
        }
      },
      {
        path: ':id',
        name: 'lectures-archive-view',
        component: () => import('../views/lectures/lectures-archive-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        meta: {
          title: 'Archived Lecture'
        }
      }
    ]
  },
  {
    path: 'companies',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'companies-list',
        component: () => import('../views/companies/companies-list/companies-list-entry.vue'),
        meta: {
          title: 'Companies',
          rootPage: true,
          isForbiddenOnGeneral: true
        }
      },
      {
        path: ':id',
        name: 'company-view',
        component: () => import('../views/companies/company-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        meta: {
          title: 'Company'
        }
      },
    ]
  },
  {
    path: 'members',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: Routes.MembersList,
        component: () => import('../views/members/members-list/members-list-entry.vue'),
        meta: {
          title: 'Members',
          rootPage: true,
          isForbiddenOnGeneral: true,
        }
      },
      {
        path: ':id',
        name: 'member-view',
        component: () => import('../views/members/member-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        children: [
          {
            path: 'meeting',
            name: 'create-meeting',
            component: () => import('../views/meetings/meeting-form.vue'),
            props: ({ params }) => ({
              invitedMemberId: parseInt(params.id as string)
            })
          }
        ],
        meta: {
          title: 'Member'
        }
      },
    ]
  },
  {
    path: 'meetings',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'meetings',
        component: () => import('../views/meetings/meetings-root.vue'),
        redirect: { name: 'meetings-list' },
        children: [
          {
            path: 'list',
            name: 'meetings-list',
            component: () => import('../views/meetings/meetings-list.vue'),
            children: [
              {
                path: ':id',
                name: 'meeting-view',
                component: () => import('../views/meetings/meeting-modal-view.vue'),
                props: ({ params }: any) => ({
                  id: parseInt(params.id),
                  status: parseInt(params.status),
                  isInvite: params.isInvite === 'true',
                }),
                meta: {
                  title: 'Meeting View',
                  saveScrollPosition: true
                }
              },
            ],
            meta: {
              title: 'Meetings List'
            }
          },
          {
            path: 'calendar',
            name: 'meetings-calendar',
            component: () => import('../views/meetings/meetings-calendar.vue'),
            /* props: ({ query }) => ({
                  start: query.start,
                  end: query.end
                }), */
            children: [
              {
                path: ':id',
                name: 'calendar-meeting-view',
                component: () => import('../views/meetings/meeting-modal-view.vue'),
                props: ({ params }: any) => ({
                  id: parseInt(params.id)
                })
              },
            ],
            meta: {
              title: 'Meetings Calendar'
            }
          }
        ],
        meta: {
          title: 'Meetings',
          rootPage: true
        }
      }
    ]
  },
  {
    path: 'profile',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'profile',
        component: () => import('../views/profile/profile-view.vue'),
        meta: {
          title: 'Profile',
          rootPage: true
        }
      },
      {
        path: 'settings',
        name: 'profile-settings',
        component: () => import('../views/profile/profile-settings-root/profile-settings-root-entry.vue'),
        redirect: { name: 'profile-main' },
        children: [
          {
            path: 'main',
            name: 'profile-main',
            component: () => import('../views/profile/profile-settings-main/profile-settings-main-entry.vue'),
            meta: {
              title: 'Profile Settings'
            }
          },
          {
            path: 'account',
            name: 'profile-account',
            component: () => import('../views/profile/profile-settings-account.vue'),
            meta: {
              title: 'Profile Account'
            }
          },
          {
            path: 'company',
            name: 'profile-company',
            component: () => import('../views/profile/profile-settings-company.vue'),
            meta: {
              title: 'Profile Company'
            }
          },
          {
            path: 'security',
            name: 'profile-security',
            component: () => import('../views/profile/profile-settings-security/profile-settings-security-entry.vue'),
            meta: {
              title: 'Profile Security'
            }
          },
        ]
      },
      {
        path: 'create_game',
        name: 'game-create',
        component: () => import('../views/profile/profile-game-form.vue'),
        meta: {
          title: 'Profile Game'
        }
      },
      {
        path: 'edit_game/:id',
        name: 'game-edit',
        component: () => import('../views/profile/profile-game-form.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        meta: {
          title: 'Profile Game'
        }
      },
    ]
  },
  {
    path: 'vacancies',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'vacancies-list',
        component: () => import('../views/vacancies/vacancies-list/vacancies-list-entry.vue'),
        meta: {
          title: 'Vacancies',
          rootPage: true
        }
      },
      {
        path: ':id',
        name: 'vacancies-view',
        component: () => import('../views/vacancies/vacancies-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        meta: {
          title: 'Vacancy'
        }
      },
    ]
  },
  {
    path: 'video-call',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: ':id?',
        name: 'video-call-view',
        component: () => import('../views/video-call/video-call-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: params.id
        }),
        meta: {
          title: 'Video Call',
          fullscreen: true
        }
      },
    ]
  },
  {
    path: 'news',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'news',
        component: () => import('../views/news/news-list.vue'),
        meta: {
          title: 'News',
          rootPage: true,
        }
      },
      {
        path: ':id',
        name: 'news-view',
        component: () => import('../views/news/news-item-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: params.id
        }),
        meta: {
          title: 'News View',
        }
      },
    ]
  },
  {
    path: 'group-call',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: ':id?',
        name: 'group-call',
        component: () => import('../views/video-call/group-call.vue'),
        meta: {
          title: 'Group Call Home',
          rootPage: true
        }
      }
    ]
  },
  {
    path: 'chats',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: ':id?',
        name: 'chats-view',
        component: () => import('../views/chats/chats-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: params.id
        }),
        meta: {
          title: 'Chat',
          rootPage: true,
          fullscreen: true
        }
      }
    ]
  },
  {
    path: 'speakers',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'speakers',
        component: () => import('../views/speakers/speakers.vue'),
        meta: {
          title: 'Speakers',
          isForbiddenOnGeneral: true
        }
      }
    ]
  },
  {
    path: 'showcase/:id?',
    component: () => import('../layouts/main/main-entry.vue'),
    meta: {
      isForbiddenOnGeneral: true
    },
    children: [
      {
        path: '',
        name: 'showcase-requests',
        component: () => import('../views/showcase/showcase-requests-list.vue'),
        props: ({ params }: RouterProps) => ({
          id: params.id
        }),
        meta: {
          title: 'Showcase Requests',
          rootPage: true
        }
      },
      {
        path: 'showcase-game/:requestId',
        name: 'showcase-request',
        component: () => import('../views/showcase/game-view.vue'),
        props: ({ params }: { params: { requestId: string, id: string }}) => ({
          requestId: parseInt(params.requestId),
          showcaseSlug: params.id,
        }),
        meta: {
          title: 'Showcase Request'
        }
      }
    ]
  },
  {
    path: 'games/:id',
    component: () => import('../layouts/main/main-entry.vue'),
    meta: {
      isForbiddenOnGeneral: true
    },
    children: [
      {
        path: '',
        name: 'showcase',
        redirect: { name: 'showcase-requests' },
      },
      {
        path: 'game/:gameId',
        name: 'game-view',
        redirect: ({ params }) => ({
          name: (params.eventSlug ? EVENT_ROUTE_PREFIX : '') + 'single-game-view',
          params: {
            eventSlug: params.eventSlug,
            id: params.gameId
          }
        })
      }
    ]
  },
  {
    path: 'game/:id',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'single-game-view',
        component: () => import('../views/showcase/game-view.vue'),
        props: ({ params }: RouterGameProps) => ({
          gameId: parseInt(params.id),
        }),
        meta: {
          title: 'Game',
          rootPage: true,
        }
      }
    ]
  },
  {
    path: 'publishers',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'publishers',
        component: () => import('../views/publishers/publishers-list.vue'),
        meta: {
          title: 'Publishers Pavilion',
          rootPage: true,
          isForbiddenOnGeneral: true
        }
      },
    ]
  },
  {
    path: 'media',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'media',
        component: () => import('../views/media/media-list.vue'),
        meta: {
          title: 'Media',
          rootPage: true,
          isForbiddenOnGeneral: true
        }
      },
    ]
  },
  {
    path: 'voting',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: ':id?',
        name: 'voting',
        component: () => import('../views/voting/voting-root.vue'),
        props: ({ params }: RouterProps) => ({
          id: params.id
        }),
        meta: {
          title: 'Voting',
          rootPage: true,
          isForbiddenOnGeneral: true
        },
        children: [
          {
            path: '',
            name: 'voting-view',
            component: () => import('../views/voting/voting-view.vue'),
            props: ({ params }: RouterProps) => ({
              id: params.id
            }),
            meta: {
              title: 'Voting'
            }
          }
        ]
      }
    ]
  },
  {
    path: 'auth',
    component: emptyRouteComponent,
    redirect: { name: 'auth-login' },
    meta: {
      guest: true,
    },
    children: [
      {
        path: 'recovery',
        name: 'auth-recovery',
        component: () => import('../views/auth/auth-recovery/auth-recovery-entry.vue')
      },
      {
        path: 'reset-password',
        name: 'auth-reset',
        component: () => import('../views/auth/auth-reset.vue')
      },
      {
        path: 'register',
        name: 'auth-register',
        component: () => import('../views/auth/auth-register.vue')
      },
      {
        path: 'magic-code',
        name: 'auth-magic-code',
        component: () => import('../views/auth/auth-magic-code.vue')
      },
    ]
  },
  {
    path: 'auth',
    component: emptyRouteComponent,
    redirect: { name: 'auth-login' },
    meta: {
      auth: true,
    },
    children: [
      {
        path: 'login',
        name: 'auth-login',
        component: () => import('../views/auth/auth-login/auth-login-entry.vue')
      },
      {
        path: 'magic/:id',
        name: 'auth-magic',
        component: () => import('../views/auth/auth-magic.vue'),
        props: ({ params }: RouterProps) => ({
          id: params.id
        }),
        meta: {
          allowAuthorized: true,
        }
      },
      {
        path: 'google',
        name: 'auth-google',
        component: () => import('../views/auth/auth-google.vue')
      },
    ]
  },
  {
    path: 'program-shared',
    name: 'program-shared',
    component: () => import('../views/program/program-shared-view.vue'),
    meta: {
      guest: true,
      isForbiddenOnGeneral: true
    },
  },
  {
    path: 'forbidden',
    name: 'forbidden',
    component: () => import('../views/errors/forbidden.vue')
  },
  {
    path: 'error',
    name: 'server-error',
    component: () => import('../views/errors/server-error.vue')
  },
  {
    path: 'network-error',
    name: Routes.NetworkError,
    component: NetworkError,
    props: ({ params }: { params: { to: string }}) => ({ to: params.to }),
    meta: {
      title: 'Network error',
      rootPage: true,
      guest: true
    }
  },
  {
    path: 'profile-data',
    component: () => import('../layouts/basic.vue'),
    children: [
      {
        path: '',
        name: 'fill-profile',
        component: () => import('../views/fill-profile/fill-profile-view.vue')
      },
    ]
  },
  {
    path: ':pathMatch(.*)*',
    name: '404',
    component: () => import('../views/errors/not-found.vue')
  },
]

// note that we prefix only names and not paths, that maybe inportant for redirects
function prefixRouteNames(route: RouteRecordRaw, prefix: string): RouteRecordRaw {
  return {
    ...route,
    ...(route?.name && { name: `${prefix}${String(route?.name || '')}` }),
    ...(route?.redirect && typeof route.redirect === 'object' && 'name' in route.redirect && route?.redirect?.name && {
      redirect: {
        ...route.redirect,
        name: `${prefix}${String(route?.redirect?.name || '')}`
      }
    }),
    children: (route?.children || []).map(child => prefixRouteNames(child, prefix))
  }
}

export default [
  {
    path: '/',
    component: emptyRouteComponent,
    children: [
      ...isGeneral
        ? [...commonRoutes,
          {
            path: 'events',
            component: () => import('../layouts/main/main-entry.vue'),
            children: [
              {
                path: '',
                name: 'events',
                component: () => import('../views/events/events-view.vue'),
                meta: {
                  title: 'Events',
                  rootPage: true
                }
              },
            ]
          },
          {
            path: 'events/:eventSlug',
            name: Routes.Event,
            component: emptyRouteComponent,
            children: [
              ...commonRoutes.map(child => prefixRouteNames(child, EVENT_ROUTE_PREFIX)),
            ]
          }]
        : commonRoutes,
    ]
  },
]
