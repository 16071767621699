import { Expose, Type, Transform } from 'class-transformer'
import { BaseModel } from './baseModel'
import { Game, GameLite } from '/~/models/game'

enum ShowcaseStatus {
  Created = 1,
  Approved,
  Declined,
  Canceled
}

enum PollEntity {
  Game = 1,
  Entity,
}

export class Vote extends BaseModel {
  id!: number

  @Expose({ name: 'user_id' })
  userId!: number

  @Expose({ name: 'entity_id' })
  entityId!: number

  @Expose({ name: 'entity_type' })
  entityType!: PollEntity

  @Expose({ name: 'poll_stage_id' })
  pollStageId!: number

  score!: number

  isGameVote() {
    return this.entityType === PollEntity.Game
  }
}

export enum RatingScale {
  Like = 1,
  ThreePoint = 3,
  FivePoint = 5,
  TenPoint = 10
}

export type ShowcaseVotes = {
  [key: number]: number
}
export class ShowcaseMeta extends BaseModel {
  id!: number

  @Expose({ name: 'event_id' })
  eventId!: number

  name!: string

  slug!: string

  @Expose({ name: 'poll_id' })
  pollId!: number

  @Expose({ name: 'poll_stage_id' })
  pollStageId!: number

  @Expose({ name: 'is_voting_open' })
  isVotingOpen!: boolean

  @Expose({ name: 'rating_scale' })
  ratingScale!: RatingScale

  @Expose({ name: 'votes' })
  @Type(() => Vote)
  @Transform(({ value }) => {
    return value
      .reduce((map: ShowcaseVotes, vote: Vote) => {
        return {
          ...map,
          ...(vote.isGameVote() && { [vote.entityId]: vote.score })
        }
      }, {})
  }, { toClassOnly: true })
  votesMap: ShowcaseVotes = {}
}

export class ShowcaseRequestLite extends BaseModel {
  id!: number

  status!: ShowcaseStatus

  @Expose({ name: 'showcase_id' })
  showcaseId!: number

  @Expose({ name: 'is_on_site' })
  isOnSite!: boolean

  @Expose({ name: 'is_contest_participant' })
  isContestParticipant!: boolean

  payload?: any

  @Expose({ name: 'created_at' })
  createdAt!: string

  @Type(() => GameLite)
  game!: GameLite

  @Expose({ name: 'is_owner' })
  isOwner?: boolean

  votes?: {
    current: number
    min: number | null
  }
}

export class ShowcaseRequest extends ShowcaseRequestLite {
  @Type(() => Game)
  game!: Game
}
