import api from '/-/plugins/api'
import { useAuth } from '/-/plugins/auth'
import { useLocale } from '/-/plugins/locale'
import { isAbortError } from '/-/plugins/helpers'
import { useAuthToken } from '/~/plugins/auth-token'
import { useLogout } from '/~/plugins/logout'
import { useEvents } from '/~/state/events'
import { unref, ref, App } from 'vue'
import router from '/~/router'
import { Routes, EventRoutes } from '/~/router/types'
import { useRoute } from '/~/state/route'
import { useEventRoute } from '/~/plugins/event-route'

interface ApiConfigHeadersParams {
  url: string
  resource?: string
  params?: Record<string, any>
  lang?: string
}

async function refreshToken() {
  const { auth, refresh } = useAuth()
  const { saveToken } = useAuthToken()
  const { logout } = useLogout()

  if (auth.value) {
    try {
      const token = await refresh()

      await saveToken(token)
    } catch (error) {
      await logout()
      window.location.reload()
    }
  } else {
    // TODO: refactor within https://app.asana.com/0/1198300825304238/1201415106593699/f
    // quick fix to prevent infinity requests from provider refresh logic
    window.location.reload()
  }
}

async function fetchErrorHandler(error: any) {
  const route = useRoute()
  const { eventSlug } = useEventRoute(ref(router.currentRoute.value))

  const errorRoute = eventSlug.value ? EventRoutes.NetworkError : Routes.NetworkError

  if (route.name !== errorRoute && !isAbortError(error)) {
    await router.replace({
      name: errorRoute,
      params: {
        to: route.fullPath
      }
    })
  }

  throw error
}

const config = {
  default: 'gateway',
  fetchErrorHandler,
  resources: [
    {
      name: 'gateway',
      baseUrl: import.meta.env.VITE_API_GATEWAY_URL,
      async headers(params: ApiConfigHeadersParams) {
        const { auth } = useAuth()
        const { lang } = useLocale()

        return {
          Authorization: auth && `Bearer ${auth.value?.accessToken}`,
          'Accept-Language': params.lang || lang.value,
        }
      },
      refreshToken,
    },
    {
      name: 'gateway-admin',
      baseUrl: import.meta.env.VITE_API_GATEWAY_URL + '/admin',
      async headers(params: ApiConfigHeadersParams) {
        const { auth } = useAuth()
        const { lang } = useLocale()

        return {
          Authorization: auth && `Bearer ${auth.value?.accessToken}`,
          'Accept-Language': params.lang || lang.value,
        }
      },
      refreshToken,
    },
    {
      name: 'gateway-oauth',
      baseUrl: import.meta.env.VITE_API_GATEWAY_URL,
      async headers(params: ApiConfigHeadersParams) {
        const { auth } = useAuth()
        const { lang } = useLocale()

        return {
          Authorization: auth && `Bearer ${auth.value?.accessToken}`,
          'Accept-Language': params.lang || lang.value,
        }
      },
    },
    {
      name: 'talentsingames',
      baseUrl: 'https://talentsingames.com/api/job-api/v2'
    },
    {
      name: 'gwo',
      baseUrl: 'https://gameworldobserver.com',
    },
    {
      name: 'a2t',
      baseUrl: 'https://app2top.ru',
    },
    {
      name: 'bigquery',
      baseUrl: import.meta.env.VITE_BIGQUERY_URL
    },
    {
      name: 'local',
      baseUrl: ''
    },
    {
      name: 'expo-games',
      baseUrl: import.meta.env.VITE_EXPO_GAMES_API_BASE_URL,
      async headers() {
        const { auth } = useAuth()
        const accessToken = unref(auth)?.accessToken

        return {
          Authorization: (accessToken && `Bearer ${accessToken}`) || '',
        }
      },
      refreshToken,
    },
  ]
}

export default {
  install: (app: App) => {
    app.use(api, config)
  }
}
