import { reactive, toRef } from 'vue'
import { Api } from '/-/plugins/api'
import ProcessorElastic from '/-/plugins/processor/processor-elastic'
import { ShowcaseRequest, ShowcaseRequestLite, ShowcaseMeta } from '/~/models/showcase-request'
import { plainToClass } from '/-/plugins/helpers'
import { useEvents } from '/~/state/events'

interface ShowcaseRequestsStateInterface {
  meta: { [id: string]: ShowcaseMeta },
  list: ProcessorElastic | null
}
const { eventId } = useEvents()

const state = reactive({
  meta: {},
  list: null
}) as ShowcaseRequestsStateInterface

function initProcessor(isOnsiteParticipantsEnabled: boolean, isContestParticipantsEnabled: boolean) {
  if (state.list) { return }
  state.list = new ProcessorElastic({
    filter: {
      search: {
        type: String
      },
      sort: {
        type: String
      },
      platform_id: {
        type: Array
      },
      genre_id: {
        type: Array
      },
      engine_id: {
        type: Array
      },
      is_on_site: {
        type: String,
        default: isOnsiteParticipantsEnabled.toString()
      },
      is_contest_participant: {
        type: String,
        default: isContestParticipantsEnabled.toString()
      }
    },
    fetch: async (page, params, fetchParams) => {
      const data = await Api.fetch({
        url: `/${eventId.value}/showcase-requests`,
        params: {
          sort: params.filter.sort || undefined,
          page,
          'filter[slug]': params.showcase,
          'filter[query]': params.filter.search || undefined,
          'filter[platforms]': params.filter.platform_id || undefined,
          'filter[genres]': params.filter.genre_id || undefined,
          'filter[engines]': params.filter.engine_id || undefined,
          'filter[is_on_site]': params.filter.is_on_site === 'true' ? 1 : undefined,
          'filter[is_contest_participant]': params.filter.is_contest_participant === 'true' ? 1 : undefined,
        },
        ...fetchParams
      })

      return data
    },
    mapping: (data) => plainToClass(data, ShowcaseRequestLite),
    allowCancel: true,
  })
}

function resetProcessor() {
  state.list = null
}

async function getShowcaseRequest(id: number | string = 'me'): Promise<ShowcaseRequest> {
  const { data } = await Api.fetch({
    url: `/${eventId.value}/showcase-requests/${id}`,
  })

  return plainToClass(data, ShowcaseRequest)
}

async function voteForGame(pollId: number, stageId: number, gameId: number, showcaseSlug: string, value: number) {
  await Api.fetch({
    url: `/${eventId.value}/poll/${pollId}/stages/${stageId}/games/${gameId}`,
    method: 'POST',
    formData: {
      score: value
    }
  })

  if (state.meta[showcaseSlug]) {
    state.meta[showcaseSlug].votesMap[gameId] = value
  }
}

async function fetchShowcaseMeta(showcaseSlug: string) {
  if (state.meta[showcaseSlug]) { return }

  const { data } = await Api.fetch({
    url: `/${eventId.value}/showcases/${showcaseSlug}`,
  })

  state.meta[showcaseSlug] = plainToClass(data, ShowcaseMeta)
}

function clearShowcaseMeta() {
  state.meta = {}
}

export function useShowcaseRequests() {
  return {
    requests: toRef(state, 'list'),
    meta: toRef(state, 'meta'),
    initProcessor,
    resetProcessor,
    getShowcaseRequest,
    clearShowcaseMeta,
    voteForGame,
    fetchShowcaseMeta,
  }
}
