import core from './core'
import auth from './auth'
import chat from './chat'
import menu from './menu'
import games from './games'
import errors from './errors'
import company from './company'
import vacancy from './vacancy'
import profile from './profile'
import lectures from './lectures'
import meetings from './meetings'
import notifications from './notifications'
import catalogs from './catalogs'
import news from './news'
import events from './events'

export default {
  core,
  auth,
  chat,
  menu,
  games,
  errors,
  company,
  profile,
  vacancy,
  lectures,
  meetings,
  notifications,
  catalogs,
  news,
  events,
}
