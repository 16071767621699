import marked, { RendererExtension, Token, TokenizerExtension, Tokens } from 'marked'

const embedYoutube: TokenizerExtension & RendererExtension = {
  name: 'embedYoutube',
  level: 'block',
  start(src: string) {
    return src.match(/^:\[(.*)]/)?.index as number
  },
  tokenizer(src: string) {
    const rx1 = /^:\[(.*)]/
    const rule = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/
    const matchMedia = src.match(rx1)
    const match = matchMedia && matchMedia[1].match(rule)

    if (match) {
      return {
        type: 'embedYoutube',
        raw: matchMedia[0],
        text: match[7].trim(),
        tokens: matchMedia[0].trim() as unknown as Token[] // tokens must be type of "Token[] | undefined", so we do this type assertion, but we don't know why it works
      }
    }
  },
  renderer(token: Tokens.Generic) {
    return `<div class="w-full relative rounded-md overflow-hidden shadow-lg" style="padding-bottom: 56%;"><iframe class="w-full h-full absolute top-0 left-0" type="text/html" src="https://www.youtube.com/embed/${token.text}" frameborder="0"></iframe></div>`
  }
}

export default {
  install: () => {
    marked.use({ extensions: [embedYoutube]})
  }
}

// https://gist.github.com/tunnckoCore/9374172
