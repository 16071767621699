import config from './config'
import { checkConfig, checkAdBlock, createMetrika } from './helpers'
import router from '/~/router'
import { AnalyticsInterface } from '/~/plugins/config'

export async function initYandexMetrika(options: AnalyticsInterface) {
  config.id = options.id
  config.debug = options.debug
  config.ignoreRoutes = options.ignoreRoutes
  config.options = {
    ...config.options,
    ...options.options
  }

  try {
    checkConfig()
    checkAdBlock()

    const metrika = await createMetrika()

    router.afterEach((to, from) => {
      if ((options.ignoreRoutes || []).indexOf(to.name as string) > -1) { return }

      if (options.skipSamePath && to.path === from.path) { return }

      metrika.hit(to.path, { referer: from.path })
    })
  } finally {
    // !
  }
}

export function useYandexMetrika() {
  return {
    initYandexMetrika
  }
}
