export default {
  main: 'Main Stream',
  showcase: 'Showcase',
  indie: 'Indie',
  roasting: 'Roasting',
  show_lecture: 'Show Lecture',
  speakers: 'Speakers',
  expand: 'Expand',
  archive_title: 'Lectures archive',
  category_all: 'All',
  agree_badge: 'Exclusive session',
  agree_button: 'Join',
}
