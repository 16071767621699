import { useEvents } from '/~/state/events'
import { useCatalogs } from '/~/state/catalogs'
import { useConfig } from '/~/plugins/config'
import { useYandexMetrika } from '/~/plugins/yandex-metrika'
import { useGoogleTag } from '/~/plugins/google-tag'
import { useWebFont } from '/~/plugins/web-font'
import { useLocale } from '/-/plugins/locale'
import { useColor } from '/-/plugins/color'
import { useTheme } from '/-/plugins/theme'
import { Storage } from '/-/plugins/storage/local'
import { useBranding } from '/~/state/branding'
import translates from '/~/languages'
import { nextTick } from 'vue'
import { Routes } from '/~/router/types'
import router from '/~/router'

async function switchEvent(eventId?: number | string) {
  const { getEvent, getCurrentEvent } = useEvents()
  const { setConfig } = useConfig()
  const { loadCatalogs } = useCatalogs()
  const { clearBranding } = useBranding()

  try {
    const event = eventId ? await getEvent(eventId) : await getCurrentEvent()

    setConfig(event.config)
    clearBranding()

    await nextTick()
    configure()
    initAddons()
    loadCatalogs()
  } catch (error: any) {
    // setup lang for error pages
    const { addLanguage, setLang, languages } = useLocale()
    const item = [...translates].find(({ abbr }) => abbr === 'en')

    if (item && languages.value.length === 0) {
      addLanguage(item)
      setLang('en')
    }

    if (error.status === 404) {
      router.replace({ name: Routes.NotFound, params: { pathMatch: router.currentRoute.value.path.substring(1).split('/') }})
    } else {
      throw error
    }
  }
}

function configure() {
  const { event } = useEvents()
  const { config } = useConfig()
  const { setTheme } = useTheme()
  const { addLanguage, setLang, clearLanguages } = useLocale()
  const { setColor, setPrimary } = useColor()
  const localLang = Storage.get('language')
  const eventLanguages = config.value.languages || ['en']

  if (eventLanguages) {
    clearLanguages()

    eventLanguages.forEach((lang) => {
      const item = [...translates].find(({ abbr }) => abbr === lang)

      if (item) {
        addLanguage(item)
      }
    })

    if (localLang && eventLanguages.includes(localLang)) {
      setLang(localLang)
    } else {
      setLang(eventLanguages[0])
    }
  }

  if (config.value.theme?.name) {
    setTheme(config.value.theme?.name)
  }

  if (config.value.theme?.color) {
    setColor(config.value.theme?.color)
  }

  if (config.value.theme?.palette) {
    setPrimary(config.value.theme?.palette)
  }

  if (event.value?.name) {
    document.title = event.value.name
  } else {
    document.title = 'WN Hub'
  }
}

async function initAddons() {
  const { config } = useConfig()
  const { initYandexMetrika } = useYandexMetrika()
  const { initGoogleTag } = useGoogleTag()
  const { loadFont, setFont } = useWebFont()

  if (config.value.theme?.font) {
    loadFont(config.value.theme?.font)
    setFont(config.value.theme?.font)
  }

  if (config.value.analytic?.yandexMetrika?.id) {
    try {
      await initYandexMetrika(config.value.analytic.yandexMetrika)
    } catch (err) {}
  }

  if (config.value.analytic?.googleTag?.id) {
    try {
      await initGoogleTag(config.value.analytic.googleTag)
    } catch (err) {}
  }
}

export function useAppEvent() {
  return {
    switchEvent,
    initAddons,
    configure
  }
}
