import { reactive, toRef, readonly, watch } from 'vue'
import { Api } from '/-/plugins/api'
import { useLocale } from '/-/plugins/locale'
import { plainToClass } from '/-/plugins/helpers'
import { useGeo } from './geo'
import { useEvents } from './events'
import { Dictionary, DictionaryItemInterface } from '/~/models/dictionary'

interface CatalogStateInterface extends Record<string, any> {
  countries: DictionaryItemInterface[]
  regions: DictionaryItemInterface[]
  sponsorTypes: DictionaryItemInterface[]
  game: {
    engines: DictionaryItemInterface[]
    genres: DictionaryItemInterface[]
    platforms: DictionaryItemInterface[]
    stages: DictionaryItemInterface[]
    looking_for: DictionaryItemInterface[]
    monetization: DictionaryItemInterface[]
  }
  company: {
    tag: DictionaryItemInterface[]
    business: DictionaryItemInterface[]
    size: DictionaryItemInterface[]
  }
  program: {
    section: DictionaryItemInterface[]
  }
  lecture: {
    category: DictionaryItemInterface[]
  }
  publisher: {
    looking_for: DictionaryItemInterface[]
  }
  user: {
    can_offer: DictionaryItemInterface[],
    looking_for: DictionaryItemInterface[]
  }
  meeting: {
    locations: DictionaryItemInterface[]
  }
}

const state: CatalogStateInterface = reactive({
  countries: [],
  regions: [],
  sponsorTypes: [],
  game: {
    engines: [],
    genres: [],
    platforms: [],
    stages: [],
    looking_for: [],
    monetization: []
  },
  company: {
    tag: [],
    business: [],
    size: []
  },
  program: {
    section: []
  },
  lecture: {
    category: []
  },
  publisher: {
    looking_for: []
  },
  user: {
    can_offer: [],
    looking_for: []
  },
  meeting: {
    locations: [
      { value: 1, label: 'Online' }
    ]
  }
})
const { lang } = useLocale()

watch(lang, () => {
  loadCatalogs().then()
})

async function getGamesDictionaries(): Promise<Dictionary[]> {
  const { data } = await Api.fetch({
    url: '/dictionaries/event'
  }) as { data: any[] }

  return plainToClass(data, Dictionary)
}

async function getDictionaries(): Promise<Dictionary[]> {
  const { data } = await Api.fetch({
    url: '/dictionaries/auth'
  }) as { data: any[] }

  return plainToClass(data, Dictionary)
}

async function getCatalogByName(name: string) {
  const { data } = await Api.fetch({
    url: '/dictionaries/event/' + name
  })

  return data
}

async function getCatalogs() {
  const [gameCatalogs, otherDictionaries] = await Promise.all([getGamesDictionaries(), getDictionaries()])

  gameCatalogs.concat(otherDictionaries).forEach(dictionary => {
    const [domain, dictionaryName] = dictionary.name.split('/')

    if (state[domain]) {
      state[domain][dictionaryName] = dictionary.items
    }
  })

  const { getCountriesAll } = useGeo()

  state.countries = await getCountriesAll()
  state.lecture.category = state.lecture.category.map((category) => {
    if (category.value === 1) {
      category.icon = 'outline_cube'
    }

    if (category.value === 2) {
      category.icon = 'outline_code'
    }

    if (category.value === 3) {
      category.icon = 'outline_library'
    }

    if (category.value === 4) {
      category.icon = 'outline_user_group'
    }

    if (category.value === 5) {
      category.icon = 'outline_color_swatch'
    }

    if (category.value === 6) {
      category.icon = 'outline_trending_up'
    }

    if (category.value === 7) {
      category.icon = 'outline_briefcase'
    }

    if (category.value === 8) {
      category.icon = 'outline_share'
    }

    if (category.value === 9) {
      category.icon = 'outline_refresh'
    }

    if (category.value === 10) {
      category.icon = 'outline_currency_dollar'
    }

    if (category.value === 11) {
      category.icon = 'outline_chart_square_bar'
    }

    if (category.value === 12) {
      category.icon = 'outline_chart_bar'
    }

    if (category.value === 13) {
      category.icon = 'outline_users'
    }

    if (category.value === 14) {
      category.icon = 'outline_cash'
    }

    if (category.value === 15) {
      category.icon = 'outline_play'
    }

    if (category.value === 16) {
      category.icon = 'outline_sparkles'
    }

    return category
  })

  const { event } = useEvents()

  if (event?.value?.points) {
    const data = JSON.parse(event.value.points)

    Object.entries<number>(data).map(([label, value]) => ({ value, label })).forEach(option => {
      state.meeting.locations.push(option)
    })
  }

  return state
}

function setSponsorTypes() {
  const { getLocal } = useLocale()

  state.sponsorTypes = [
    {
      value: 0,
      label: 'Exhibitor',
      badge: 'bg-primary-100 text-primary-800'
    },
    {
      value: 1,
      label: getLocal('catalogs.sponsors.bronze_sponsor'),
      badge: 'bg-yellow-600 text-yellow-100'
    },
    {
      value: 2,
      label: getLocal('catalogs.sponsors.silver_sponsor'),
      badge: 'bg-gray-500 text-gray-100'
    },
    {
      value: 3,
      label: getLocal('catalogs.sponsors.gold_sponsor'),
      badge: 'bg-yellow-200 text-yellow-700'
    },
    {
      value: 4,
      label: getLocal('catalogs.sponsors.platinum_sponsor'),
      badge: 'bg-gray-200 text-gray-700'
    },
    {
      value: 5,
      label: getLocal('catalogs.sponsors.organizer'),
      badge: 'bg-primary-100 text-primary-800'
    },
    {
      value: 6,
      label: getLocal('catalogs.sponsors.general_sponsor'),
      badge: 'bg-primary-100 text-primary-800'
    },
    {
      value: 7,
      label: getLocal('catalogs.sponsors.diamond_sponsor'),
      badge: 'bg-blue-100 text-blue-800'
    },
  ]
}

function setRegions() {
  const { getLocal } = useLocale()

  state.regions = [
    { value: 'EU', label: getLocal('catalogs.regions.central_western_europe') },
    { value: 'EEU', label: getLocal('catalogs.regions.eastern_europe') },
    { value: 'NA', label: getLocal('catalogs.regions.north_america') },
    { value: 'AS', label: getLocal('catalogs.regions.asia') },
    { value: 'SCA', label: getLocal('catalogs.regions.south_central_america') },
    { value: 'ME', label: getLocal('catalogs.regions.middle_east') },
    { value: 'AF', label: getLocal('catalogs.regions.africa') },
    { value: 'OC', label: getLocal('catalogs.regions.australia_oceania') },
  ]
}

async function loadCatalogs() {
  setRegions()
  setSponsorTypes()
}

function getTagsAutocomplete(query: string) {
  const options = state.company.tag

  if (!query || query === '') {
    return options
  }

  return options.filter(option => option.label.toLowerCase().includes(query.toLowerCase()))
}

function getBusinessAutocomplete(query: string) {
  const options = state.company.business

  if (!query || query === '') {
    return options
  }

  return options.filter(option => option.label.toLowerCase().includes(query.toLowerCase()))
}

function getPlatformsAutocomplete(query: string) {
  const options = state.game.platforms

  if (!query || query === '') {
    return options
  }

  return options.filter(option => option.label.toLowerCase().includes(query.toLowerCase()))
}

function getUserLookingForAutocomplete(query: string) {
  const options = state.user.looking_for

  if (!query || query === '') {
    return options
  }

  return options.filter(option => option.label.toLowerCase().includes(query.toLowerCase()))
}

function getPublisherLookingForAutocomplete(query: string) {
  const options = state.publisher.looking_for

  if (!query || query === '') {
    return options
  }

  return options.filter(option => option.label.toLowerCase().includes(query.toLowerCase()))
}

function getGameLookingForAutocomplete(query: string) {
  const options = state.game.looking_for

  if (!query || query === '') {
    return options
  }

  return options.filter(option => option.label.toLowerCase().includes(query.toLowerCase()))
}

function getOffersAutocomplete(query: string) {
  const options = state.user.can_offer

  if (!query || query === '') {
    return options
  }

  return options.filter(option => option.label.toLowerCase().includes(query.toLowerCase()))
}

async function getSectionOptions() {
  if (!state.program.section || !state.program.section.length) {
    try {
      const data = await getCatalogByName('program/section')

      state.program.section = data.items
    } catch (e) {
      state.program.section = []
    }
  }
  return state.program.section
}

function getRegionsAutocomplete(query: string) {
  if (!query || query === '') {
    return state.regions
  }

  return state.regions.filter(option => option.label.toLowerCase().includes(query.toLowerCase()))
}
function getGenresAutocomplete(query: string) {
  const options = state.game.genres

  if (!query || query === '') {
    return options
  }

  return options.filter(option => option.label.toLowerCase().includes(query.toLowerCase()))
}
function getEnginesAutocomplete(query: string) {
  const options = state.game.engines

  if (!query || query === '') {
    return options
  }

  return options.filter(option => option.label.toLowerCase().includes(query.toLowerCase()))
}

export function useCatalogs() {
  return {
    tags: readonly(toRef(state.company, 'tag')),
    business: readonly(toRef(state.company, 'business')),
    employees: readonly(toRef(state.company, 'size')),
    offers: readonly(toRef(state.user, 'can_offer')),
    userLookingFor: readonly(toRef(state.user, 'looking_for')),
    gameLookingFor: readonly(toRef(state.game, 'looking_for')),
    publisherLookingFor: readonly(toRef(state.publisher, 'looking_for')),
    engines: readonly(toRef(state.game, 'engines')),
    genres: readonly(toRef(state.game, 'genres')),
    platforms: readonly(toRef(state.game, 'platforms')),
    stages: readonly(toRef(state.game, 'stages')),
    monetization: readonly(toRef(state.game, 'monetization')),
    lectureArchiveCategories: readonly(toRef(state.lecture, 'category')),
    regions: readonly(toRef(state, 'regions')),
    countries: readonly(toRef(state, 'countries')),
    sponsorTypes: readonly(toRef(state, 'sponsorTypes')),
    meetingLocations: readonly(toRef(state.meeting, 'locations')),
    loadCatalogs,
    getCatalogs,
    getTagsAutocomplete,
    getBusinessAutocomplete,
    getPlatformsAutocomplete,
    getUserLookingForAutocomplete,
    getPublisherLookingForAutocomplete,
    getGameLookingForAutocomplete,
    getSectionOptions,
    getOffersAutocomplete,
    getRegionsAutocomplete,
    getGenresAutocomplete,
    getEnginesAutocomplete
  }
}
