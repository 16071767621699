import { useApplication } from '/~/state/application'
import { Storage } from '@capacitor/storage'
import cookieStorage from '/-/plugins/storage/cookies'
import { UserAuthTokenInterface } from '/~/models/user-auth'

const TOKEN_NAME = 'gateway-auth'

async function getToken() {
  const { isNative } = useApplication()

  if (isNative) {
    const token = await Storage.get({ key: TOKEN_NAME })

    return token.value && JSON.parse(token.value)
  } else {
    const token = cookieStorage.get(TOKEN_NAME)

    return token && JSON.parse(decodeURIComponent(token))
  }
}

export async function saveToken(token: UserAuthTokenInterface) {
  const { isNative } = useApplication()

  if (isNative) {
    await Storage.set({
      key: TOKEN_NAME,
      value: JSON.stringify(token)
    })
  } else {
    cookieStorage.set(TOKEN_NAME, JSON.stringify(token), token.expires_in)
  }
}

export async function removeToken() {
  const { isNative } = useApplication()

  if (isNative) {
    await Storage.remove({ key: TOKEN_NAME })
  } else {
    cookieStorage.remove(TOKEN_NAME)
  }
}

export function useAuthToken() {
  return {
    getToken,
    saveToken,
    removeToken,
  }
}
