export default {
  not_select_title: 'Welcome to the WN Hub chat!',
  not_select_text: 'Chatting with other participants is easy!',
  not_select_text_1: 'Go to {link} and choose a person you want to talk to.',
  not_select_text_1_link: 'Attendees',
  not_select_text_2: 'Push the ‘Send message’ button in the chosen profile and write your message.',
  not_select_text_3: 'All done! You can also reply to other people\'s messages.',
  not_select_text_mobile: 'Go to {link} and choose a person to start a new conversation.',
  replay: 'Reply',
  delete: 'Delete',
  type: 'Type a message...',
  main_chat: 'Main Chat',
  meeting_ready: 'Meeting is ready',
  join: 'Join Now',
  share: 'Share Link',
  one_member: 'You are the only participant',
  members: 'Members',
  join_meeting: 'Join the meeting',
  settings: 'Meeting Settings',
  all_chats: 'All Chats',
  private_chats: 'Private Chats',
  company_chats: 'Company Chats',
  grid: 'Grid',
  grid_plus: 'Grid With Me',
  accent: 'Accent',
  exit: 'Exit',
  copy_link: 'Copy Link',
  joining_info: 'Joining Info',
  show_chat: 'Show chat',
  hide_chat: 'Hide chat',
  start_chat: 'Start chat',
  audio_device: 'Audio Device',
  video_device: 'Video Device',
  question_speaker: 'Question for the speaker',
  unread_hidden_message: 'Unread messages are hidden',
  empty: 'Go to the Attendees section and find relevant people to make business with!',
  mute: 'Mute',
  unmute: 'Unmute',
  report: 'Report a message',
}
