export default {
  create: 'Create Meeting',
  create_online: 'Create Online Meeting',
  is_online: 'Online meeting',
  agenda: 'Agenda',
  date: 'Date',
  time_slot: 'Time Slot',
  time: 'Time',
  request: 'Request',
  participants: 'Particiants',
  cancel: 'Cancel',
  meetings: 'Meetings',
  meeting: 'Meeting',
  join_meeting: 'Video Call',
  calendar: 'Calendar',
  select_timeslot_prompt: 'Select Time Slot',
  today: 'Today',
  invites: 'Invites',
  upcoming: 'Upcoming',
  location: 'Location',
  online: 'Online',
  onsite: 'On-site',
  invited: 'Invited',
  accepted: 'Accepted',
  rejected: 'Rejected',
  cancel_verb: 'Cancel',
  reschedule: 'Reschedule',
  send_message: 'Send Message',
  reject: 'Reject',
  accept: 'Accept',
  reason: 'Reason',
  cancel_meeting: 'Cancel Meeting',
  save: 'Save',
  day: 'Day',
  days: 'Days',
  week: 'Week',
  time_slot_locked_message: `You locked this time slot.
  Unlock it in your schedule or reschedule this meeting for another time slot.`,
  time_slot_busy_message: `You already have a meeting scheduled for this time.
  Reschedule this meeting for another time slot.`,
  no_meetings: 'No Meetings',
  status_waiting: 'outcoming invite',
  status_declined: 'declined',
  status_accepted: 'accepted',
  status_incoming: 'incoming invite',
  unlock: 'unlock',
  lock: 'lock',
  location_hint_text: 'To get around the expo zone in Shanghai please check out',
  location_hint_link: 'the floor plan'
}
