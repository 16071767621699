import { Expose, Exclude, Transform, Type } from 'class-transformer'
import { useCatalogs } from '/~/state/catalogs'
import { formatLinks, removeTags } from '/-/plugins/format'
import { Company, CompanyLite } from './company'
import { Media } from '/~/models/media'
import { BaseModel } from './baseModel'
import marked from 'marked'
import { useConfig } from '/~/plugins/config'
import { EntryItem } from './entry'
import dayjs from '@litvinovvo/dayjs'

export class UserLite extends BaseModel {
  id!: number
  job!: string
  timezone!: string

  @Type(() => Media)
  media!: Media[]

  @Expose({ name: 'first_name' })
  firstName!: string

  @Expose({ name: 'last_name' })
  lastName!: string

  @Type(() => CompanyLite)
  company!: CompanyLite

  get avatarUrl(): string {
    const logo = this.media?.find((media) => media.category === 'logo')

    if (!logo) {
      return ''
    }
    return logo.url.startsWith('http') ? logo.url : import.meta.env.VITE_BASE_MEDIA_URL + logo.url
  }

  get fullName(): string {
    return this.firstName + ' ' + this.lastName
  }

  get companyName() {
    return this.company?.name || ''
  }
}

export class User extends UserLite {
  @Exclude({ toPlainOnly: true })
  email?: string

  phone?: string

  @Exclude({ toPlainOnly: true })
  @Type(() => Company)
  company!: Company

  @Expose({ name: 'is_password_set' })
  hasPassword!: boolean

  bio!: null | string

  city!: string

  linkedin?: string

  @Expose({ name: 'city_id' })
  cityId!: null | number

  portfolio!: null | string

  @Expose({ name: 'created_at' })
  createdAt!: string

  @Expose({ name: 'disable_email_notifications' })
  disableEmailNotifications!: boolean

  geo!: string

  languages!: null | string

  @Expose({ name: 'offer_ids', toPlainOnly: true })
  @Transform(({ value }) => value.map((entry: EntryItem) => entry.id), { toClassOnly: true })
  offer!: number[]

  @Expose({ name: 'looking_ids', toPlainOnly: true })
  @Transform(({ value }) => value.map((entry: EntryItem) => entry.id), { toClassOnly: true })
  looking!: number[]

  @Expose({ name: 'company_id' })
  @Transform(({ obj }) => { return obj.company?.id || null }, { toClassOnly: true })
  companyId?: number | null = null

  get created(): number {
    return dayjs.utc(this.createdAt).valueOf()
  }

  get countryName() {
    const { countries } = useCatalogs()

    return countries.value.find(({ value }) => value === this.geo)?.label
  }

  get bioHtml(): string {
    if (!this.bio) {
      return ''
    }

    const bio = this.bio.replace(/&/g, '&amp;')
      .replace(/>/g, '&gt;')
      .replace(/</g, '&lt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;')
      .replace(/\n/g, '<br />')

    return formatLinks(bio)
  }

  get bioMarked(): string {
    return marked(removeTags(this.bio || ''))
      .replace(/<a href=/g, '<a target="_blank" href=')
  }

  get portfolioMarked(): string {
    return marked(removeTags(this.portfolio || ''))
      .replace(/<a href=/g, '<a target="_blank" href=')
  }

  get offers() {
    const { offers } = useCatalogs()

    return this.offer.map((item) => {
      return offers.value.find(({ value }) => value === item)
    })
  }

  get isComplete(): boolean {
    const { config } = useConfig()

    return config.value?.isB2C
      ? this.isCompleteB2CEventUser
      : this.isCompleteB2BEventUser
  }

  get isCompleteB2BEventUser(): boolean {
    return !!(this.firstName &&
      this.lastName &&
      this.job &&
      this.geo &&
      this.cityId)
  }

  get isCompleteB2CEventUser(): boolean {
    return !!(this.firstName &&
      this.email)
  }
}

export interface PasswordDataInterface {
  current_password: string
  new_password: string
  repeat_password: string
}
