import config from './config'

declare let Ya: {
  Metrika2: (data: Record<string, any>) => void
}

export function checkConfig() {
  if (typeof document === 'undefined') { return }

  if (!config.id) {
    throw new Error('Please enter a Yandex Metrika tracking ID')
  }
}

export function checkAdBlock() {
  const el = document.getElementById('wrapfabtest')

  if (el && el.clientHeight === 0) {
    throw new Error('AdBlock Detected')
  }
}

async function loadScript(src: string) {
  return new Promise((resolve, reject) => {
    const head = document.head || document.getElementsByTagName('head')[0]
    const script = document.createElement('script')

    script.async = true
    script.charset = 'utf-8'
    script.src = src

    head.appendChild(script)

    script.onload = () => resolve({ success: true })
    script.onerror = error => reject(error)
  })
}

function debug(message: string, args?: any) {
  if (config.debug) {
    console.log(`[${config.name}] ${message}`, args)
  }
}

export async function createMetrika() {
  await loadScript(config.scriptSrc)

  if (import.meta.env.VITE_ENV === 'production') {
    const yaMetrika = new (Ya.Metrika2 as any)({
      id: config.id,
      ...config.options
    })

    window[`yaCounter${config.id}`] = yaMetrika

    return yaMetrika
  } else {
    console.warn('Tracking is disabled, because env option is not "production"')
    debug('DEBUG is true: you\'ll see all API calls in the console')

    return {
      addFileExtension(...args: any[]) { debug('addFileExtension:', args) },
      extLink(...args: any[]) { debug('extLink:', args) },
      file(...args: any[]) { debug('file:', args) },
      getClientID(...args: any[]) { debug('getClientID:', args) },
      hit(...args: any[]) { debug('hit:', args) },
      notBounce(...args: any[]) { debug('notBounce:', args) },
      params(...args: any[]) { debug('params:', args) },
      reachGoal(...args: any[]) { debug('reachGoal:', args) },
      replacePhones(...args: any[]) { debug('replacePhones:', args) },
      setUserID(...args: any[]) { debug('setUserID:', args) },
      userParams(...args: any[]) { debug('userParams:', args) }
    }
  }
}
