import { computed, reactive, toRef } from 'vue'
import { Storage } from '/-/plugins/storage/local'
import { PasswordDataInterface, User } from '/~/models/user'
import { Member } from '/~/models/member'
import { Api } from '/-/plugins/api'
import { useEvents } from '/~/state/events'
import { plainToClass, classToPlain } from '/-/plugins/helpers'
import * as Sentry from '@sentry/vue'
import { useChat } from '/~/state/sendbird'
import { Ticket } from '/~/models/ticket'

const { eventId } = useEvents()

interface ProfileStateInterface {
  member?: Member
  ticket?: Ticket
  chatToken?: string | null
}

const state: ProfileStateInterface = reactive({
  profile: undefined,
  member: undefined,
  ticket: undefined,
  language: undefined,
  chatToken: undefined
})

if (Storage.get('member')) {
  state.member = plainToClass(Storage.get('member'), Member)
}

function setMember(profile: Member) {
  state.ticket = plainToClass(profile.ticket, Ticket)
  state.member = plainToClass(profile, Member)
  Storage.set('profile', classToPlain(profile, Member))
  Sentry.setUser({
    id: profile?.user.id.toString(),
    email: profile?.user.email
  })
}

async function removeProfile() {
  const { disconnectChat } = useChat()

  await disconnectChat()
  state.chatToken = null
  Storage.remove('profile')
  Sentry.setUser(null)
}

let fetchMemberPromise: null | Promise<{ data: Member }> = null

async function fetchMember(): Promise<{ data: Member } | void> {
  if (state.member) {
    return
  }
  if (fetchMemberPromise) {
    return fetchMemberPromise
  }
  fetchMemberPromise = Api.fetch({
    url: `/${eventId.value}/members/me`,
  })

  const data = await fetchMemberPromise

  data && setMember(data.data)

  fetchMemberPromise = null
}

async function reloadMember(): Promise<void> {
  const { data } = await Api.fetch({
    url: `/${eventId.value}/members/me`,
  }) as { data: Member }

  setMember(data)
}

async function getProfileChatToken(): Promise<string> {
  if (!state.chatToken) {
    const { data } = await Api.fetch(`/${eventId.value}/chat/token`) as { data: { token: string } }

    state.chatToken = data.token
  }

  return state.chatToken
}

async function getProfileVideoToken(channel: string) {
  const { data } = await Api.fetch(`/video/token/${channel}`)

  return data
}

async function updateMember(userData: Partial<User>): Promise<void> {
  const { data } = await Api.fetch({
    url: `/${eventId.value}/members/me`,
    method: 'PATCH',
    body: classToPlain(userData, User)
  })

  setMember(data)
}

async function uploadMedia(
  file: File,
  category?: 'image' | 'logo' | 'background'
) {
  const formData = new FormData()

  formData.append('category', category || 'logo')
  formData.append('media', file)

  const { data } = await Api.fetch({
    url: '/users/me/media',
    method: 'POST',
    formData: {
      category: category || 'logo',
      media: file
    }
  })

  return data
}

async function updateProfilePassword(passwordData: PasswordDataInterface): Promise<void> {
  await Api.fetch({
    url: '/users/me/change-password',
    method: 'POST',
    body: classToPlain(passwordData)
  })
}

async function updateProfileTicket() {
  const { data } = await Api.fetch(`/${eventId.value}/members/me/upgrade-url`)

  return data.url
}

export function useProfile() {
  return {
    ticket: toRef(state, 'ticket'),
    member: toRef(state, 'member'),
    profile: computed(() => state.member?.user),
    fetchMember,
    reloadMember,
    updateMember,
    updateProfilePassword,
    removeProfile,
    getProfileChatToken,
    getProfileVideoToken,
    setMember,
    uploadMedia,
    updateProfileTicket,
  }
}
