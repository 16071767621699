export default {
  not_select_title: 'Добро пожаловать в чат WN Hub!',
  not_select_text: 'Начать общение с другими участниками мероприятия просто!',
  not_select_text_1: 'Перейдите в раздел {link} и выберите собеседника.',
  not_select_text_1_link: 'Участники',
  not_select_text_2: 'Нажмите "Отправить сообщение" в выбранном профиле и напишите ваше сообщение.',
  not_select_text_3: 'Готово! Также вы можете отвечать на сообщения от других пользователей WN Hub.',
  not_select_text_mobile: 'Перейдите в раздел {link} и выберите собеседника чтобы начать новый диалог',
  replay: 'Ответить',
  delete: 'Удалить',
  type: 'Введите сообщение...',
  main_chat: 'Общий чат',
  meeting_ready: 'Встреча готова',
  join: 'Подключиться',
  share: 'Поделиться',
  one_member: 'Вы единственный участник',
  members: 'Участники',
  join_meeting: 'Присоединиться',
  settings: 'Настройка связи',
  all_chats: 'Все чаты',
  private_chats: 'Личные чаты',
  company_chats: 'Чаты компании',
  grid: 'Сетка',
  grid_plus: 'Сетка со мной',
  accent: 'Акцент',
  exit: 'Выйти',
  copy_link: 'Скопировать ссылку',
  joining_info: 'Ссылка для подключения',
  show_chat: 'Показать чат',
  hide_chat: 'Спрятать чат',
  start_chat: 'Открыть чат',
  audio_device: 'Аудио',
  video_device: 'Видео',
  question_speaker: 'Вопрос спикеру',
  unread_hidden_message: 'Непрочитанные сообщения скрыты',
  empty: 'Go to the Attendees section and find relevant people to make business with!',
  mute: 'Заблокировать',
  unmute: 'Разблокировать',
  report: 'Пожаловаться на сообщение',
}
