import { Transform, Type, Expose } from 'class-transformer'
import { ConfigInterface } from '/~/plugins/config'
import { BaseModel } from './baseModel'
import { Media } from './media'

export class Event extends BaseModel {
  id!: number
  slug!: string
  config!: ConfigInterface
  latitude!: string
  longitude!: string
  name!: string
  points?: string
  timezone!: string
  host!: string
  address!: string

  @Type(() => Media)
  background!: Media

  @Type(() => Media)
  logo!: Media

  @Expose({ name: 'date_from' })
  dateFrom!: string

  @Expose({ name: 'date_to' })
  dateTo!: string

  @Expose({ name: 'is_online' })
  isOnline!: boolean

  @Expose({ name: 'is_onsite' })
  isOnsite!: boolean

  @Transform(({ value }) => !!value, { toClassOnly: true })
  is_all_users!: boolean

  @Transform(({ value }) => !!value, { toClassOnly: true })
  auto_reg!: boolean

  @Transform(({ value }) => !!value, { toClassOnly: true })
  active!: boolean

  get position() {
    return {
      lat: this.latitude,
      lng: this.longitude,
    }
  }
}
export class SortedEvents extends BaseModel {
  @Type(() => Event)
  past: Event[] = []

  @Type(() => Event)
  current: Event[] = []

  @Type(() => Event)
  upcoming: Event[] = []
}

export class SortedEventsGroup extends BaseModel {
  @Type(() => SortedEvents)
  user!: SortedEvents

  @Type(() => SortedEvents)
  all!: SortedEvents
}
