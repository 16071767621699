export default {
  unmute: '非静音',
  mute: '静音',
  empty: '前往“参会者”页面，寻找相关人士开始商谈！',
  start_chat: '开始聊天',
  not_select_title: '欢迎来到 WN Hub 聊天室!',
  not_select_text: '与其他参会者交谈十分轻松！',
  not_select_text_1: '去往 {link} 选择一位参会者开始交谈.',
  not_select_text_1_link: '参会者',
  not_select_text_2: '在嘉宾详情页点击“发送信息”，即可编写信息。',
  not_select_text_3: '全部搞定！ 你也可以回复其他人的信息。',
  not_select_text_mobile: '去往 {link} 选择一位参会者开始新的对话。',
  replay: '重播',
  delete: '删除',
  type: '输入消息……',
  main_chat: '重要聊天',
  meeting_ready: '会议已准备就绪',
  join: '立即加入',
  share: '共享链接',
  one_member: '您是唯一的参与者',
  members: '成员',
  join_meeting: '加入会议',
  settings: '会议设置',
  all_chats: '所有聊天',
  private_chats: '私人聊天',
  company_chats: '公司聊天',
  grid: '网格',
  grid_plus: '加入我的网格视图',
  accent: '口音',
  exit: '退出',
  copy_link: '复制链接',
  joining_info: '加入信息',
  show_chat: '显示聊天',
  hide_chat: '隐藏聊天',
  audio_device: '音频设备',
  video_device: '视频设备',
  question_speaker: '向演讲人提出的问题',
  unread_hidden_message: '屏蔽未读信息。',
  report: '举报留言',
}
