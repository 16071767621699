export default {
  lectures: '直播演讲',
  lectures_archive: '演讲回放',
  program: '演讲安排',
  chats: '聊天',
  showcase: '开发者展示',
  members: '参会者',
  companies: '公司介绍',
  consultants: '顾问',
  vacancies: '职位',
  meetings: '会议',
  speakers: '发言人',
  group_call: '群组通话',
  agenda: '时间表',
  contacts: '联系人',
  events: '活动',
  publishers: '发行商展区',
  expo: '会场',
  media: '媒体合作伙伴',
  host: '主持自己的活动',
  our_media: '自媒体',
  partyspace: '派对区域',
  news: '新闻消息'
}
