import { Expose, Type } from 'class-transformer'
import { useProfile } from '/~/state/profile'
import { formatLinks } from '/-/plugins/format'
import { BaseModel } from './baseModel'
import dayjs from '@litvinovvo/dayjs'

export class MessageApiUser extends BaseModel {
  nickname!: string
  role!: string

  @Expose({ name: 'user_id' })
  userId!: string

  @Expose({ name: 'is_active' })
  isActive!: boolean

  @Expose({ name: 'profile_url' })
  plainProfileUrl!: string
}

export class MessageApi extends BaseModel {
  message!: string
  data!: string

  @Expose({ name: 'channel_type' })
  channelType!: string

  @Expose({ name: 'channel_url' })
  channelUrl!: string

  @Expose({ name: 'custom_type' })
  customType!: string

  @Expose({ name: 'mention_type' })
  mentionType!: string

  @Expose({ name: 'req_id' })
  reqId!: string

  @Expose({ name: 'is_op_msg' })
  isOperatorMessage!: boolean

  @Expose({ name: 'sending_status' })
  sendingStatus!: string

  @Expose({ name: 'request_state' })
  requestState!: string

  @Expose({ name: 'message_type' })
  messageType!: string

  @Expose({ name: 'message_id' })
  messageId!: number

  @Expose({ name: 'created_at' })
  createdAt!: number

  @Expose({ name: 'user' })
  @Type(() => MessageApiUser)
  sender!: null | MessageApiUser

  @Type(() => MessageApiUser)
  mentionedUsers!: [] | MessageApiUser[]

  get id(): number {
    return this.messageId
  }

  get messageHtml(): string {
    if (!this.message) {
      return ''
    }

    return formatLinks(this.message)
      .replace(/\n/g, '<br />')
  }

  get isAdmin(): boolean {
    return this.messageType === 'admin'
  }

  get isMeAuthor(): boolean {
    const { profile } = useProfile()

    return !!this.sender && parseInt(this.sender.userId) === profile.value?.id
  }

  get time() {
    return this.createdAt ? dayjs(this.createdAt).format('HH:mm') : ''
  }

  get date() {
    return this.createdAt ? dayjs(this.createdAt).format('DD.MM.YYYY') : ''
  }

  get replayedUser(): MessageApiUser | undefined {
    return undefined
  }

  get isEmoji(): boolean {
    const regexExp = /^(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])$/gmi

    return regexExp.test(this.message)
  }
}
