export const URL_REGEXP = /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/

export function checkUrl(url: string) {
  return url.match(URL_REGEXP)
}

export const YOUTUBE_REGEXP = /^(http(s)??\:\/\/)?(www\.)?((youtube\.com\/(?:watch\?v=|shorts\/))|(youtu.be\/))((?:[a-zA-Z0-9\-_])+)(?:\?feature=share)?$/

export function getYouTubeVideoId(url: string): string {
  const match = url.match(YOUTUBE_REGEXP)

  return (match && match[7].length === 11) ? match[7] : ''
}

export function isYouTubeUrl(url: string): boolean {
  return !!getYouTubeVideoId(url)
}
