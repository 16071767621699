export default {
  lectures: 'Лекции',
  lectures_archive: 'Архив лекций',
  program: 'Программа',
  chats: 'Чаты',
  showcase: 'Шоукейс',
  members: 'Участники',
  companies: 'Компании',
  consultants: 'Консультанты',
  vacancies: 'Вакансии',
  meetings: 'Встречи',
  speakers: 'Спикеры',
  group_call: 'Групповой звонок',
  agenda: 'Программа',
  contacts: 'Контакты',
  events: 'Мероприятия',
  publishers: 'Издатели',
  host: 'Проведите мероприятие',
  expo: 'Экспо Зона',
  media: 'Медиа партнёры',
  our_media: 'Наши сми',
  partyspace: 'Party Space',
  news: 'Новости'
}
