export default {
  required: 'Required',
  email: 'Must be a valid email',
  min: 'The value must be at least 0:{length} characters long',
  max: 'The value may not be greater than 0:{length} characters',
  confirmed: 'Cannot confirm. Values do not match',
  regex: 'Invalid characters',
  url: 'Invalid URL',
  youtube: 'Invalid YouTube link',
  name: 'Ivalid characters',
}
