export default {
  not_found_title: 'Ooops... 404!',
  not_found_text: 'The page you requested could not be found.',
  not_found_button: 'Return to Hub',
  error_title: 'Server Error',
  forbidden_title: 'Forbidden',
  forbidden_text: 'Register to start using the platform.',
  forbidden_text_1: 'Ooops! You need a ticket to access the ongoing event.',
  forbidden_text_2: 'Go back and Sign In to a different account or Register.',
  room_full_title: 'Room is full',
  room_full_text: 'Try to join again later',
  change_account: 'Sign in to another account',
  link_error_title: 'Link Expired',
  link_error_text: 'That magic link has expired',
  upgrade_required_title: 'Upgrade required',
  upgrade_required_text_1: 'At this stage there are no more free passes available for service providers.',
  upgrade_required_text_2: 'If you would like to join the :name, please purchase a Business Pass',
  upgrade_required_text_2_1: 'If you’d like to unlock this feature, please upgrade your ticket here.',
  upgrade_required_text_2_2: 'If you would like to join Stream please upgrade your ticket here.',
  upgrade_required_text_2_3: 'Contact us if you have questions',
  upgrade_required_text_3: 'Do not forget to refresh the page after the purchase is made.',
  upgrade_required_button: 'Upgrade',
  no_network_title: 'No Internet Connection',
  no_network_text: 'Please, check your network connection and update the page',
  no_network_button: 'Retry',
  vote_failed: 'Vote failed',
  no_company_title: 'Company required',
  no_company_body: 'If you would like to add a game, please set company in your profile.',
  no_company_tip: 'Do not forget to refresh the page after setting the company.',
}
